import React, { useEffect } from "react";
import img from "../../Images/b2.png";

import SmallStrip from "../../components/SmallStrip";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Mainland = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Mainland</title>
        <meta name="description" content="All license here" />
      </Helmet>
      {/* <SmallStrip data="Mainland" data2="Licensing" /> */}

      {/* <div className="row">
        <img src={img2} alt="error" className=" rounded" />

      </div> */}

      <SmallStrip data="Mainland" data2="Licensing" />

      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded margin-top4" />
          </div>
          <div className="col-md-6 col-lg-7 col-xl-8">
            <p className="h3 bold-text">Dubai Mainland License, UAE</p>
            <p className="mt-1 font-size-para" >
              The most lucrative and profitable option when investing or establishing one’s company in Dubai; is the mainland company formation. All mainland companies are allowed to operate well within the UAE jurisdiction which includes commercialized geographical regions. The much-coveted Dubai Mainland license is issued almost exclusively by the Dubai Economic Department whether your business idea is professional, commercial, a single establishment, or a combination type of the above.   One of the prime reasons one needs to have a Dubai mainland license is that along with the flexibility needed by a business to grow is the rewards one reaps from the growth gained. An essential part of any business set up in Dubai or elsewhere is having a trade license as it assures the clientele of the legitimacy of the company and how it is in regulation by Dubai and UAE laws. Certain rules and policies are amended by the government that needs to be followed when operating in the mainland of Dubai or UAE. While previously it was a must for mainland bigger businesses to have an Emirati shareholder who owns more than the foreigner, now most business ideas or when opening a branch of an already existing business allows complete foreign ownership. Another advantage of opting for investment in the mainland rather than the free zones is how one can extend their branches of business to not just other parts of Dubai but anywhere in the UAE. Licensing types that mostly come under mainland licensing are Professional, Commercial, Industrial, Tourism, and E Trader licenses.
            </p>

            <Link to="/contact" className="button-animation">
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>



      <div className="container-fluid padding3">
        <div className="row">
          <center>
            <p className="h1 bold-text ">Mainland</p>
          </center>
          <br></br>

          <br></br>
          <br></br>
          <p>
            Mainland companies are the best and most rewarding of investments and company setups. Workings of the mainland company are not restricted in any manner as compared to a free zone company’s geographical boundaries. The most sought-after mainland emirate by budding entrepreneurs is Dubai because of its presence as a main business hub and friendly policies that often allow complete foreign ownership.
          </p>
          {/* 
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div> */}


        </div>
      </div>
      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">Some Fundamental Formalities when setting up a business in Dubai!</p>
        <br></br>
        <div className="row">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-6 col-lg-4   mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className=" bold-text d-flex" style={{ textTransform: "capitalize" }} >What Is The Relevance of a Dubai Mainland Company? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2" >The mainland company often referred to as an onshore company is a company registered at the Department of Economic Development in Dubai. Thereby availing a trouble-free trading experience in Dubai free of unnecessary taxing. Also, the location for the office space is easy as there are no geographical boundaries for the same. Recent Companies Law amendments allow foreigners a chance to own the complete mainland business. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex" style={{ textTransform: "capitalize" }} >What Are The Essential Steps When Applting For a Dubai Mainland Company License?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">For a Dubai mainland company license, many government organization approvals are required. The essential steps that seek approval include the selection of business activity and trade name, an initial certificate for mainland company setup, notarization by the court, annotation of the associative team, choice of office space location and tenancy requirements, final submission letter, and assimilation of documents.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">IN A MAINLAND BUSINESS, HOW MANY VISAS CAN BE OBTAINED?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Diplomat Documents Services (DDS) ensures you are aware of exactly how many visas you can apply for when opening a business in mainland Dubai. While there are no direct restrictions against the number of visas but every 9 sqm of office space allows the allotment of about one visa. Therefore, the bigger the office space more the visas your company may receive. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2" >
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">UAE Branch Office Set-Up</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Setting up a business in UAE can be done in many ways, but methods to retain complete ownership while doing so are trickier. Hence opening the branch office to an already existing company is often a popular choice among immigrant entrepreneurs. DDS is able to help guide you through all the legal paperwork and hassles if this is how you plan to set up an office.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2" style={{ height: "100%" }}>
                <div className="card-faq shadow">
                  <div className="mx-4">
                    <p className="bold-text d-flex">Corporate Banking</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Corporate banking is one of the most integral formalities that need to be dealt with when one opts to establish one’s startup in Dubai. It can also be complicated when trying to open a bank account on your own. Hence, DDS offers all the banking assistance one may require from starting a banking account for corporate purposes to even managing the same once the company has been set up.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
      <br></br>
      <br></br>
      <br></br>

      <hr></hr>
    </>
  );
};

export default Mainland;
