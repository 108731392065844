import React, { useEffect } from "react";

import Caursol from "../homecomponents/Caursol";
import Needs from "../homecomponents/Needs";
import Services from "../homecomponents/Services";
import { Helmet } from "react-helmet";
import { AiOutlineWhatsApp } from "react-icons/ai";


const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Home Page</title>
        <meta name="description" content="All license here" />
      </Helmet>
      <Caursol />
      <Needs />
      <Services />

      <a href="https://wa.me/971508878699" className="float" target="_blank" rel="noreferrer">
        <AiOutlineWhatsApp className="fa fa-whatsapp my-float" />

      </a>

      {/* <div className="container-fluid padding3">
        <div className="row mt-2">
          <p className="bold-text text-secondary">
            Loram Business Setup and Support Services
          </p>
          <p className="text-secondary">
            Are you planning for a business setup in Dubai/UAE? We can help you
            to set it up from the initial stages itself. Our team of
            professionals have exceptional knowledge and experience in setting
            up a business in Dubai. It will begin from finding a reliable
            sponsor for your business, obtaining a license and all the other
            formalities for successful inception and fruitful operation of your
            business in UAE. We will also guide you regarding the best type of
            license in Dubai depending on the choice of your business. There are
            three types of business licenses in UAE. Those are Mainland, Free
            Zone and Offshore licenses. Each type of license has its pros and
            cons. It is essential to understand the best option for your
            business. Thereby, you can enhance the profit possibilities to the
            optimum. Availing our services for company formation in Dubai/ UAE
            will be the right decision to start your dream journey towards
            business setup in Dubai.
          </p>
        </div>
        <div className="row  mt-2">
          <p className="bold-text text-secondary">Company Formation in Dubai</p>
          <p className="text-secondary">
            Dubai has been the most sought after destination for business
            organisations across the globe. It has played a pivotal role in the
            growth of some of the most prominent business firms. The federal
            laws in the United Arab Emirates have been conducive for the
            commencement of businesses. Hence, Dubai is the best option for
            anyone dreaming of a great future in business. The city with
            well-developed and superior infrastructure facilities offers the
            best atmosphere for businesses. With regulations that are
            advantageous for the firms to gain boundless profit and prosper, the
            influx of expats to begin business operations in Dubai has been
            increasing rapidly. You can establish a Mainland, Free Zone or
            Offshore Company in Dubai.
          </p>
        </div>

      </div> */}

    </>
  );
};

export default Home;
