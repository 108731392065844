import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Contact from "../components/Contact";

import { store } from "../FirebaseConfig";
import image from "../Images/aboutdds.png"


const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [aboutUsInfo, setAboutUsInfo] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(store, "Aboutus"));
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setAboutUsInfo(list);
        console.log("res", list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <>

      <Helmet>
        <title>About US</title>
        <meta name="description" content="All license here" />
      </Helmet>
      {aboutUsInfo.map((item) => (
        <>
          <div className="container mt-5">
            <div className="row ">
              <div className="col-md-4">

                <img src={image} alt="error" className="img-fluid rounded  rounded margin-top4" ></img>
              </div>
              <div className="col-md-8">
                <p className="h3 bold-text"> {item.heading}</p>
                <p style={{ fontSize: "15px", marginTop: "0rem" }} > {item.discription}</p>
              </div>
            </div>

            <p style={{ fontSize: "15px" }} className="mt-3"> {item.information}</p>
          </div>
        </>
      ))
      }
      <br></br>
      <br></br>
      <Contact />
      <br></br>
      <br></br>
    </>
  );
};

export default About;
