import React from "react";
import "./caursal.css";

import banner from "../Images/banner05.png";
// import Typical from "react-typical";

import { Link } from "react-router-dom";

const Caursol = () => {

  return (
    <>
      <div className="container-banner">
        <img src={banner} alt="error" className="img-fluid img-size" />
        <div className="div-banner">
          {" "}
          <p className="text-small-banner1">Business Setup Consultants</p>
          <div>
            <p className="text-white font-size-slider bold-text d-flex">
              {/* <BsBuilding className=" icon-banner" /> */}
              SIMPLIFY THE START OF A <br></br>
              BUSINESS IN DUBAI
            </p>
          </div>
          {/* <hr className="color-hr2" size="4px"></hr>
          <p className="text-small-banner1">2022</p> */}
          {/* <p className="text-small-banner2">
            <Typical
              loop={Infinity}
              className="text-small-banner2"
              steps={[
                " HOW TO START YOUR COMPANY IN DUBAI",
                1000,
                "get flat 10% off for new arrivals",
                1000,
              ]}
            />
          </p> */}
          <p className="text-small-banner3 size-1">
            Has starting a company of your own in Dubai or the UAE an unfulfilled dream? Let our experience in the field lend wings to your dream and make it come true. We have helped numerous people carve a niche in the Business world of Dubai.
          </p>
          <div className="d-flex">

            <Link to="/about" className="button-animation">
              Know More
            </Link>

          </div>
        </div>
      </div>



    </>
  );
};

export default Caursol;
