import React from "react";

import Typical from "react-typical";


const SmallStrip = (prop) => {
  return (
    <>
      <br></br>
      <div className="container-fluid " style={{ padding: "0" }}>
        {/* <img src={bg} alt="error" className="img-fluid back-img shadow2" /> */}

        <div className=" h5 bold-text mx-1">
          <p>
            <Typical
              loop={Infinity}
              className=""
              steps={[`Home > ${prop.data2} > ${prop.data} `, 1000]}
            />
          </p>
        </div>
      </div>
      <br></br>
      <br></br>
    </>
  );
};

export default SmallStrip;
