import React, { useEffect } from "react";
import img from "../../../Images/12.png";
import SmallStrip from "../../../components/SmallStrip";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Tourism = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Tourism License</title>
        <meta name="description" content="All license here" />
      </Helmet>
      <SmallStrip data="Tourism" data2="Licensing" />
      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-3 col-lg-5 col-xl-4">
            <center>
              <img src={img} alt="error" className="img-fluid rounded margin-top4" />
            </center>
          </div>
          <div className="col-md-9 col-lg-7 col-xl-8">
            <p className="h3 bold-text ">Tourism License in Dubai, UAE</p>
            <p className="font-size-para">
              Throughout the vacation season in Dubai like during the Dubai shopping festival (DSF), the activity that gets noticed most is none other than tourism and is therefore often a source of pride for the UAE. Sightseers and adventurers arrive in droves to explore all that Dubai has to offer. Being the top tourist destination in the Middle East, the tourism industry in the UAE is lucrative and hence invite to be invested. But, to invest or begin a company based on travel and tourism in Dubai, one must first apply for a tourism license. Diplomat Business Solutions (DBS) can help ensure all the paperwork is correctly set and submitted properly to the Department of Tourism and Commerce Marketing (DTCM) so that they can issue a tourism license. Having been under the right administration, Dubai has blossomed from a simple desert to one of the most sought-after beautiful and modern cities. While its natural beauty is extraordinary, skyscrapers, lovely parks, and man-made islands just add to its elegance and are a reason why tourists frequent this gorgeous place each year.  While all religious people enjoy trips to the UAE, this country also makes provision for Halal tourism which in turn also hails a lot of Muslim families to visit the UAE without compromising in any manner. Another reason why tourists flock in could also be medical tourism or people traveling while searching for better employment opportunities, thereby clarifying that entertainment may not be the only reason. Keeping all this in mind it is easy to see how Dubai will always remain a top tourism hub without too much effort and so without a doubt investing in this arena will bode well for those interested in starting or establishing themselves as a part of the travel and tourism industry here in the UAE.
            </p>
            <Link to="/contact" className="button-animation">
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">FREQUENTLY ASKED QUESTIONS</p>
        <br></br>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className=" bold-text d-flex" > WHAT ARE THE BENEFITS OF HAVING A TOURISM LICENSE IN THE DUBAI MAINLAND?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">A tourism license in the Dubai mainland offers a wealth of new and profitable options due to the increased demand for tourism-related activities and because the mainland is downright more lucrative in businesses when compared to freezone areas. The same license allows the conduct of inbound, outward, and travel agency operations within UAE without the payment of any additional fees.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex"  >WHAT ARE THE TYPES OF TOURISM LICENSES IN DUBAI? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2">
                      <span className="bold-text">Inbound Tour License : </span>
                      Allows one to do business like lead tours and trips for visitors within Dubai. <br></br>
                      <span className="bold-text"> Outbound Tour License :</span>
                      Allowed to plan both domestic and abroad excursions outside of UAE.<br></br>
                      <span className="bold-text"> Travel Agent License : </span>
                      Permitted to operate in ticket sales, hotel or resort bookings as well as car rental services. <br></br>
                      <span className="bold-text">  Tour Guide License : </span>
                      DTCM issues a tour guide license for a year after passing the exam for the same. Yearly renewal is mandatory. <br></br>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow">
                  <div className="mx-4">
                    <p className="bold-text d-flex" style={{ height: "100%" }}>WHAT ARE THE ACTIVITIES THAT COME UNDER A TOURISM LICENSE? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2">DDS can help decide how best to put your business idea into practice in Dubai with this license. Services like Tourist visa services along with providing travel insurance. Selling tickets inclusive of flights, buses, or trains, and even actively engaging car or bus rental services. Other activities like booking hotels or resorts and selling tourism packages are available under this license. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <div className="card-faq shadow">
                  <div className="mx-4">
                    <p className="bold-text d-flex" style={{ height: "100%" }}>HOW CAN YOU OBTAIN A TOURISM LICENSE?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">The DTCM has attempted to make acquiring a tourist license much easier and simpler with the help of updated regulations. DBS suggests putting together some basic documents required for the application of a tourism license; which include a trading name based on the naming stipulations of the government, business activity, a passport copy of the shareholders, an entry stamp, or visa page along with a passport-size photograph.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">HOW CAN DBS LLC HELP WITH GETTING A TOURISM LICENSE?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">If launching your very own tourism company or one with other partners is what you have in mind, but are unsure of how to begin doing so, we at DBS will be delighted to take the load off your hands and not just help you set up but also help you get the most favorable of outcomes in a timely and cost-effective manner.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </div>
      <br></br>
      <br></br>
      <br></br>

      <br></br>

      <hr></hr>
    </>
  );
};

export default Tourism;
