import React from 'react'
// import {
//     collection,
//     getDocs,
//     deleteDoc,
//     doc,
//     onSnapshot,
// } from "firebase/firestore";
import { db } from '../FirebaseConfig';
import { useEffect } from 'react';
import { useState } from 'react';
import { onValue, ref } from 'firebase/database';

import UserCard from './components/UserCard';
import { Button } from 'react-bootstrap';
const Users = () => {
    const [todos, setTados] = useState([]);




    useEffect(() => {
        // const fetchData = async () => {
        //     let list = [];
        //     try {
        //         const querySnapshot = await getDocs(collection(db, "users"));
        //         querySnapshot.forEach((doc) => {
        //             list.push({ id: doc.id, ...doc.data() });
        //         });
        //         setData(list);
        //         console.log(list);
        //     } catch (err) {
        //         console.log(err);
        //     }
        // };
        // fetchData();



        onValue(ref(db), (snapshot) => {
            const data = snapshot.val();
            console.log("data", data);

            setTados(data);
            // if (data !== null) {
            //     Object.values(data).map((todo) => {
            //         console.log(todo);
            //         setTados([...todo]);
            //     });
            // }
        });


        // LISTEN (REALTIME)
        // const unsub = onSnapshot(
        //     collection(db, "users"),
        //     (snapShot) => {
        //         let list = [];
        //         snapShot.docs.forEach((doc) => {
        //             list.push({ id: doc.id, ...doc.data() });
        //         });
        //         setData(list);
        //     },
        //     (error) => {
        //         console.log(error);
        //     }
        // );

        // return () => {
        //     fetchData();
        // };
    }, [])
    return (
        <div className='container-fluid'>
            <div className="mb-3 d-flex mx-4 mt-3" style={{ justifyContent: "space-between" }} >

                <h1 variant="primary" className="font-size2" style={{ fontWeight: "bold" }}>
                    All Users
                </h1>
                <Button style={{ background: "rgb(9 7 48)" }} className="mx-1 text-white">Add Blog</Button>
            </div>
            <div style={{ width: "100%" }}>
                <table variant='simple' style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>LastName</th>
                            <th>Phone No</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.values(todos).reverse().map((user) => (
                                <>

                                    <UserCard user={user}></UserCard>

                                </>
                            ))
                        }
                    </tbody>

                </table>
            </div>

        </div>
    )
}

export default Users
