
import React from 'react'

const UserCard = (user) => {
    const { name, lastname, phone, email, mess, timeStamp } = user.user;


    const date = new Date(timeStamp).toUTCString()

    return (
        <tr>
            <td>{name}</td>
            <td>{lastname} </td>
            <td>{phone}</td>
            <td>{email}</td>
            <td>{mess}</td>
            <td>{date}</td>
        </tr>
    )
}

export default UserCard
