import React, { useEffect } from "react";
import img from "../../Images/b3.png";
import SmallStrip from "../../components/SmallStrip";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const BusinessVisa = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Business Visa </title>
        <meta name="description" content="All license here" />
      </Helmet>
      <SmallStrip data="Dubai-Business-Visa" data2="Visa" />


      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded margin-top4" />
          </div>
          <div className="col-md-6 col-lg-7 col-xl-8">
            <p className="h3 bold-text">Dubai Business Visa, Dubai</p>

            <p className="font-size-para">
              If setting up a business or venturing entrepreneurially in Dubai is what you have in mind, the prospect of doing so becomes much easier if you have acquired a Dubai Business Visa. The emirate is a current favorite as a business destination due to its topographical location, amiable constitutional system, and last but not the least, tax-free economy. The Dubai Business Visa once obtained, being a part of the UAE Golden Visa system has many perks like long-term visa benefits for both the businessperson and their dependents that helps in the growth of business often exponentially while ensuring the country’s economic progress. Often referred to as Dubai Startup Visa, this visa not only encourages an entrepreneur who already owns a business in Dubai to do so again but also welcomes newcomers with a plan or idea that they can expand or those who wish to relocate to UAE to start a business. Some of the criteria that open doors in this sector, include being an experienced entrepreneur or even having been a shareholder in a startup company. A review committee for granting the Dubai Business Visa is quite thorough in its approach to foreign applicants and requires them to follow Federal Authority for Identity and Citizenship (ICA) guidelines for visa issuance. All of this becomes less complicated when we, Diplomat Documents Service  (DDS) take up the entire process with just the right amount of time and cooperation from our client without having to face and handle any of the hassles directly.
            </p>

            <Link to="/contact" className="button-animation">
              Contact us
            </Link>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>

      </div>
      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">Some Fundamental Formalities when setting up a business in Dubai!</p>
        <br></br>
        <div className="row">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-6 col-lg-4   mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className=" bold-text d-flex" style={{ textTransform: "capitalize" }} >WHAT ARE THE TYPES OF DUBAI BUSINESS VISAS? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2" >There are two types of visas:<br></br>
                      <span className="bold-text">Six-month visa:</span> Foreign applicants are given a temporary multiple-entry type Dubai business visa valid for just six months. This needs to be converted into a residency permit before its expiry.<br></br>
                      <span className="bold-text">One-month visa:</span> Applicants residing in UAE are granted a one-month Dubai business visa that is also temporary and needs to be converted into a residency permit before expiry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex" style={{ textTransform: "capitalize" }} >WHY APPLY FOR THE DUBAI BUSINESS VISA?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Once received, the Dubai Business Visa encourages many foreign investments that often seek to and manage to help the local economy thrive and grant both international and non-resident entrepreneurs the ease with which to establish their business ventures successfully. And the visa also helps them to apply for permanent residency which in turn provides the applicants and their families security and other benefits.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">HOW TO APPLY FOR A DUBAI BUSINESS VISA?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Diplomat Documents Service  (DDS) helps you apply for a Dubai Business Visa with extended support till you have established your capitalistic ventures successfully. Our Staff will collect the required documents and upload them on the Federal Authority for Identity and Citizenship website on your behalf, follow further instructions received via email, and proceed in the right manner toward receipt of the visa.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">IS THE APPLICATION PROCESS A LONG ONE?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">DDS makes sure there are no delays from both sides and so an approximate period of about 60 days is expected give or take a few days. The 60 days are inclusive of the first 30 days it takes for initial application review following which once successfully nominated they have to fulfill the Federal Authority for Identity and Citizenship (ICA) requirements within the additional 30 days </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">CAN THE DUBAI BUSINESS VISA BE EXTENDED TO DEPENDENTS AND ALSO TO A PERMANENT RESIDENCY?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">The ICA requirements for sponsoring families in the UAE that has to be met with, for a visa holder to sponsor dependents. As the Dubai Business Visa is based on permanent residency, it can be converted to the same before expiry, but requirements set by the ICA need to be met every five years during the renewal period.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
      <br></br>
      <br></br>


      <br></br>

      <hr></hr>
    </>
  );
};

export default BusinessVisa;
