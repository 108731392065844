import "./footer.css";
import { BsBuilding } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (

    <>
      <div className="container-fluid" style={{ background: "linear-gradient(to right, #123F47 50%, rgb(68 125 135) 100%)" }}>
        <div className="row" style={{ padding: "2%" }} >
          <div className="col-md-6 center">
            <p className="text-white h2 mt-3 bold-text">Reach out to us for more information</p>
          </div>
          <div className="col-md-6 center">
            <Link to="/contact" className="button-animation">Contact Us</Link>
          </div>


        </div>
      </div>


      <footer style={{ marginBottom: "-2rem", marginTop: "0.2rem" }} >
        <div
          className="background5"
          style={{ background: "#123F47", opacity: "" }}
        >
          <div className="transbox5">
            <div className="container-fluid">
              <br></br>
              <br></br>
              <div className="row ">
                <div className="col-sm-12 col-md-6 col-lg-3 center2 mt-1 mb-5">
                  <ul style={{ padding: "0px" }}>
                    <h3 className="text-white bold-text mt-4 h3 d-flex">
                      <BsBuilding className="mx-1" />About Us{" "}
                    </h3>
                    <hr className="color-hr"></hr>

                    <p className="mt-3 text-white" style={{ fontSize: "14px" }}>
                      {" "}
                      Diplomat Documents Service is based in Dubai, a dynamic business hub; and is capable of helping anyone with a dream to not just own but successfully conduct business and be a part of the fastest growing economies. With a decade of experience in Business setup and corporate PRO services, our all-embracing attitude helps initially small, foreign but path-breaking enterprises to realize their full potential all over UAE.
                    </p>
                    <Link to="/about" className="button-animation   mt-2">
                      Know More
                    </Link>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 mt-1 mb-5 ">
                  <div className="">
                    <ul style={{ padding: "0px" }}>
                      <br />
                      <h3 className="text-white h3 bold-text">Quick Links</h3>
                      <hr className="color-hr"></hr>
                      <li className="zoom marginFooter  mt-3">
                        <Link to="/mainland" className="text-white color mt-1 ">
                          {" "}
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Mainland License{" "}
                        </Link>
                      </li>
                      <li className="zoom marginFooter  mt-3">
                        {" "}
                        <Link to="/freezone" className="text-white  color   mt-3">
                          {" "}
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Free zone License
                        </Link>
                      </li>
                      <li className="zoom marginFooter  mt-3">
                        <Link to="/offshore" className="text-white  color  ">
                          <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                          Offshore License
                        </Link>
                      </li>
                      <li className="zoom marginFooter">
                        {" "}
                        <Link to="/businessvisa" className="text-white color  ">
                          {" "}
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Dubai-Business visa
                        </Link>
                      </li>
                      <li className="zoom marginFooter  mt-3">
                        {" "}
                        <Link to="/investorvisa" className="text-white color  ">
                          {" "}
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Dubai-Investor visa
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 mt-1 mb-5">
                  <ul className="" style={{ padding: "0px" }}>
                    <br />
                    <h3 className="text-white h3 bold-text bold-text">Mainland License</h3>
                    <hr className="color-hr"></hr>
                    <li className="zoom marginFooter mt-3">
                      <Link to="/professional" className="text-white color mt-1 ">
                        {" "}
                        <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Professional License
                      </Link>
                    </li>

                    <li className="zoom marginFooter  mt-3">
                      {" "}
                      <Link to="/commericial" className="text-white  color ">
                        {" "}
                        <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Commercial License
                      </Link>
                    </li>
                    <li className="zoom marginFooter  mt-3">
                      <Link to="/industrial" className="text-white  color ">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        Industrial License
                      </Link>
                    </li>
                    <li className="zoom marginFooter">
                      {" "}
                      <Link to="/tourism" className="text-white color  ">
                        {" "}
                        <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Tourism License
                      </Link>
                    </li>
                    <li className="zoom marginFooter  mt-3">
                      {" "}
                      <Link to="/etrade" className="text-white color  ">
                        {" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i> E
                        Trader License
                      </Link>
                    </li>
                  </ul>
                </div>

                <div
                  className="col-sm-12 col-md-6 col-lg-3 mt-1 mb-2"
                  style={{ fontFamily: "Poppins" }}
                >
                  <ul style={{ padding: "0px" }}>
                    <br />
                    <h3 className="text-white h3 bold-text"> Our Contact</h3>
                    <hr className="color-hr"></hr>
                    <li className="text-white mt-2">
                      <i className="fa fa-map-marker mx-1"></i> Office No:305,Coastal Building Al Twar - 2, Near Sharjah Islamic Bank,Dubai P.O Box: 235548<br />
                    </li>
                    <li className="text-white mt-2">
                      <i className="fa fa-map-marker mx-1"></i> Office No:G 21,Arzoo Real Estate Building Al Twar - 2, Near Sharjah Islamic Bank,Dubai P.O Box: 235548<br />
                    </li>

                    <li className="text-white d-flex  mt-2" style={{ fontSize: "16px", padding: "0rem" }}>
                      {" "}
                      <i className=" fab fa-whatsapp-square  mx-1 mt-1"></i>+971 50 887 8699
                    </li>
                    <li className="text-white d-flex  mt-2" style={{ fontSize: "16px", padding: "0rem" }}>
                      {" "}
                      <i className="fa fa-phone-square  mx-1 mt-1"></i>+971 52 895 1898
                    </li>

                    <li className="text-white d-flex  mt-2" style={{ fontSize: "15px", padding: "0rem" }}>
                      {" "}
                      <i className="fa fa-phone-square   mx-1 mt-1"></i><span style={{ fontWeight: "600", display: "block" }} >Main Office:</span> <p>+971 4 326 2732</p>
                    </li>
                    <li className="text-white d-flex  " style={{ fontSize: "15px", padding: "0rem" }}>
                      {" "}
                      <i className="fa fa-phone-square  mx-1 mt-1" ></i><span style={{ fontWeight: "600" }} >Branch Office:</span> +971 4 251 0444
                    </li>
                    <li className="text-white  mt-2" style={{ fontWeight: "600" }} >
                      <i className="fa fa-envelope  mx-1"></i>
                      Info@diplomatdoc.com
                    </li>
                    <br />

                    <br />
                  </ul>
                </div>
              </div>
              <hr className="color-hr"></hr>

              <div className="icon-social color5">
                <h5 className="padding text-white h5">
                  @2022 Copyright : Info@diplomatdoc.com
                </h5>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
