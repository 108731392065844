import React from "react";
import { TbBusinessplan, TbCertificate } from "react-icons/tb";
// import b4 from "../Images/b4.jpeg";

import office from "../Images/officespace.png";
import "./services.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import { Keyboard, Pagination, Navigation } from "swiper";
import Contact from "../components/Contact";
import { Link } from "react-router-dom";
import { BsArrowDownRightSquare, BsBuilding } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { GiDesk } from "react-icons/gi";
// import { BiBuildings } from "react-icons/bi";
import logos from "../Images/companyLogos.jpeg";
import { HiDesktopComputer, HiOfficeBuilding } from "react-icons/hi";
import { MdCabin } from "react-icons/md";




const Services = () => {

  return (
    <>
      <div className="container marginTop-500">

        <div className="row">
          <div className="col-lg-6 col-xl-4 col-md-6 mt-5">
            <center>
              <Link to="/freezone" >
                <div className="box">
                  <div className="imgBox">
                    <div className="back-img-3">
                      <h1 className="heading h1"> FREEZONE </h1>
                      <center>
                        <h1 className="display h1">
                          {" "}
                          <BsArrowDownRightSquare />
                        </h1>
                      </center>
                    </div>
                  </div>
                  <div className="content shadow">
                    <h2 className="bold-text d-flex">
                      <BsBuilding
                        fontSize="40px"
                        className="mx-2 bold-text"
                        color="#397894"
                      />
                      FREEZONE LICENSE
                      <br />
                    </h2>
                  </div>
                </div>
              </Link>
            </center>
          </div>
          <div className="col-lg-6 col-xl-4 col-md-6  mt-5 center">
            <center>
              <Link to="/mainland" >

                <div className="box">
                  <div className="imgBox">
                    <div className="back-img-2">
                      <h1 className="heading h1" style={{}}>
                        MAINLAND{" "}
                      </h1>
                      <center>
                        <h1 className="display h1">
                          {" "}
                          <BsArrowDownRightSquare />
                        </h1>
                      </center>
                    </div>
                  </div>
                  <div className="content shadow">
                    <h2 className="bold-text d-flex">
                      <BsBuilding
                        fontSize="40px"
                        className="mx-2 bold-text"
                        color="#397894"
                      />
                      MAINLAND LICENSE
                      <br />
                    </h2>
                  </div>
                </div>

              </Link>
            </center>
          </div>
          <div className="col-lg-6 col-xl-4 col-md-6  mt-5 center">
            <center>
              <Link to="/offshore" >
                <div className="box">
                  <div className="imgBox">
                    <div className="back-img-1">
                      <h1 className="heading h1">OFFSHORE </h1>
                      <center>
                        <h1 className="display h1">
                          {" "}
                          <BsArrowDownRightSquare />
                        </h1>
                      </center>
                    </div>
                  </div>
                  <div className="content shadow">
                    <h2 className="bold-text d-flex">
                      <BsBuilding
                        fontSize="40px"
                        className="mx-2 "
                        color="#397894"
                      />{" "}
                      OFFSHORE LICENSE
                      <br />
                    </h2>
                  </div>
                </div>
              </Link>
            </center>
          </div>

        </div>
      </div>

      <div className="container-fluid " style={{ marginTop: "5rem" }}>
        <div className="row">
          <div className="col-lg-5 " style={{ backgroundColor: "rgb(10 128 152", color: "white" }}>

            <div className="padding-for-logo">
              <p className="text-small-banner5  bold-text">
                {/* <BsBuilding className=" icon-banner" /> */}
                We Work Closely with  <br></br>
                Government Agencies
              </p>
              <ul style={{ marginLeft: "-2rem" }}>
                <li> <p >{">>"} License and Business Registration done effciently</p></li>
                <li> <p>{">>"} Residency Visas and work Permits approved on time</p></li>
                <li><p>{">>"} Guaranteed smooth A-to-Z Process with Us</p></li>
                <li><p>{">>"} Accounting and Tax Service made easy</p></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7">
            <img src={logos} className="img-fluid" alt="error" />
          </div>
        </div>
      </div>
      <div className="container-fluid padding3 " style={{ marginTop: "3rem" }}>
        {" "}
        <div className="center">
          <p className="h3 bold-text">Company Establishing Services</p>
          <p className=" mt-4 ">
            Is being a part of the business community in Dubai a dream of yours? We at Diplomat Documents Services (DDS) can help you set up your enterprise in the most strategically favorable manner.
          </p>
          {/* <p className="bold-text h4 mt-4 text-secondary">
            Choose your business need in Dubai or UAE to continue
          </p> */}
        </div>
        <br></br>

        <div className="row">
          <div className="col-lg-4  mt-4">
            <div className="card-small-2 zoom " style={{ padding: "7px", height: "100%" }}>
              <div className="row">

                <div className="col-md-2">
                  <TbCertificate className="icon-service" style={{ fontSize: "50px", marginTop: "1rem" }} />
                </div>
                <div className="col-md-10">
                  <div className="">
                    <p className="bold-text d-flex" style={{ fontSize: "18px" }}>
                      <span className="mt-2">  Company Registration & licensing  </span>
                    </p>
                    <p style={{ fontSize: "13px", marginTop: "-0.5rem" }}>

                      DDS ensures an easy issuance of the license for the production and trading of products made within the UAE or internationally
                    </p>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div className="col-lg-4 mt-4">
            <div className="card-small-2 zoom " style={{ padding: "7px", height: "100%" }}>
              <div className="row">

                <div className="col-md-2">
                  <TbCertificate className="icon-service" style={{ fontSize: "50px", marginTop: "1rem" }} />
                </div>
                <div className="col-md-10">
                  <div className="">
                    <p className="bold-text d-flex" style={{ fontSize: "18px" }}>
                      <span className="mt-2"> Visa Services </span>
                    </p>
                    <p style={{ fontSize: "13px", marginTop: "-0.5rem" }}>
                      DDS helps you apply for a Dubai Business Visa with extended support till you have established your capitalistic ventures successfully.
                    </p>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div className="col-lg-4 mt-4">
            <div className="card-small-2 zoom " style={{ padding: "7px", height: "100%" }}>
              <div className="row">

                <div className="col-md-2">
                  <TbCertificate className="icon-service" style={{ fontSize: "50px", marginTop: "1rem" }} />
                </div>
                <div className="col-md-10">
                  <div className="">
                    <p className="bold-text d-flex" style={{ fontSize: "18px" }}>
                      <span className="mt-2">      PRO Services </span>
                    </p>
                    <p style={{ fontSize: "13px", marginTop: "-0.5rem" }}>
                      We at DBS would be honoured to ease your business burden and assist you to succeed by providing you with the finest possible outcomes in a speedy and economical manner.
                    </p>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 mt-4">
            <div className="card-small-2 zoom " style={{ padding: "7px", height: "100%" }}>
              <div className="row">

                <div className="col-md-2">
                  <TbCertificate className="icon-service" style={{ fontSize: "50px", marginTop: "1rem" }} />
                </div>
                <div className="col-md-10">
                  <div className="">
                    <p className="bold-text d-flex" style={{ fontSize: "18px" }}>
                      <span className="mt-2">    Banking Assistance  </span>
                    </p>
                    <p style={{ fontSize: "13px", marginTop: "-0.5rem" }}>
                      DBS offers all the banking assistance one may require from starting a banking account for corporate purposes to even managing the same once the company has been set up.
                    </p>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div className="col-lg-4 mt-4">
            <div className="card-small-2 zoom " style={{ padding: "7px", height: "100%" }}>
              <div className="row">

                <div className="col-md-2">
                  <TbCertificate className="icon-service" style={{ fontSize: "50px", marginTop: "1rem" }} />
                </div>
                <div className="col-md-10">
                  <div className="">
                    <p className="bold-text d-flex" style={{ fontSize: "18px" }}>
                      <span className="mt-2">   Investor Rights Protection  </span>
                    </p>
                    <p style={{ fontSize: "13px", marginTop: "-0.5rem" }}>
                      we can guide your choices based on what you have in mind and help you weigh all the options available to you to take your company to its full potential based on what Dubai has to offer.
                    </p>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div className="col-lg-4 mt-4">
            <div className="card-small-2 zoom " style={{ padding: "7px", height: "100%" }}>
              <div className="row">

                <div className="col-md-2">
                  <TbCertificate className="icon-service" style={{ fontSize: "50px", marginTop: "1rem" }} />
                </div>
                <div className="col-md-10">
                  <div className="">
                    <p className="bold-text d-flex" style={{ fontSize: "18px" }}>
                      <span className="mt-2">  Value-Added Services  </span>
                    </p>
                    <p style={{ fontSize: "13px", marginTop: "-0.5rem" }}>
                      Diplomat Documents Service (DDS) offers you 365 days customer support
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>

      <br></br>
      <br></br>

      <div className="back-img-5">


        <div className="row center ">
          <p className="h1  mx-1  bold-text">Contact Us</p>

        </div>
        <Contact />


        <br></br>
      </div>

      <br></br>
      <br></br>
      <br></br>

      <div className="container">
        <div className="row">
          <div
            className="col-lg-4 "
          // data-aos="fade-up-right"
          // data-aos-duration="3000"
          >
            <br></br>
            <img src={office} alt="error" className="img-fluid rounded zoom" />
          </div>
          <div className="col-lg-8">
            <h1 className="color2 bold-text h1  mt-4">Importance of Office Spaces</h1>

            <p className="text-secondary size-para">
              Business Set Ups in UAE are often required to have an office space to register and conduct their operations. Exceptions to this include offshore companies and those working with an E-Trader license. Based on the type of business office space requirements are different too; Onshore (or Mainland) offices are traditional office spaces that companies can lease or buy, shared offices can also be considered by onshore businesses as it is more affordable, free zone offices are an available option for those companies in the respective free zone, and Flexi-desks is an even more minimal cost option. Being unsure of the office needs that apply to one’s company is quite normal. Hence Diplomat Documents Services (DDS) can be the shoulder you can lean on, as we can find offices to suit your needs and budget
            </p>
            <br></br>
            <br></br>

          </div>
        </div>

      </div>
      <br></br>
      <div className="container-fluid padding3 " >
        <div className="row">
          <div className="col-lg-2 col-md-4 col-6  center zoom mt-2 color-hover">
            <center>
              <TbBusinessplan className="icon-service" />
            </center>
            {/* <h4 className="text-secondary h4" style={{ marginTop: "-1rem" }} >TRADE</h4> */}
            <h3 className="bold-text color-hover h5">Business Centers</h3>
          </div>
          <div className="col-lg-2 col-md-4 col-6 center zoom mt-2 color-hover">
            <center>
              <HiDesktopComputer className="icon-service" />
            </center>
            {/* <h4 className="text-secondary h4 " style={{ marginTop: "-1rem" }}>FIND A</h4> */}
            <h3 className="bold-text color-hover h5">Virtual Office</h3>
          </div>
          <div className="col-lg-2 col-md-4 col-6  center zoom mt-2 color-hover">
            <center>
              <GiDesk className="icon-service" />
            </center>
            {/* <h4 className="text-secondary h4" style={{ marginTop: "-1rem" }}>COMPANY</h4> */}
            <h3 className="bold-text color-hover h5">Flexi Desks</h3>
          </div>
          <div className="col-lg-2 col-md-4 col-6  center zoom mt-2 color-hover">
            <center>
              <MdCabin className="icon-service" />
            </center>
            {/* <h4 className="text-secondary h4" style={{ marginTop: "-1rem" }}>BRAND</h4> */}
            <h3 className="bold-text color-hover h5">Rent A Cabin</h3>
          </div>
          <div className="col-lg-2 col-md-4  col-6  center zoom  mt-2 color-hover">
            <center>
              <HiOfficeBuilding className="icon-service" />
            </center>
            {/* <h5 className="text-secondary h4" style={{ marginTop: "-1rem" }}>Rent an Office</h5> */}
            <h3 className="bold-text color-hover h5">OPENING</h3>
          </div>
          <div className="col-lg-2 col-md-4 col-6  center zoom  mt-2 color-hover">
            <center>
              <FaUsers className="icon-service" />
            </center>
            {/* <h4 className="text-secondary h4" style={{ marginTop: "-1rem" }}> PRO</h4> */}
            <h3 className="bold-text color-hover h5">find an office</h3>
          </div>

        </div>

      </div>

      <br></br>

      <div className="back-img-5 mt-5">
        <p className="h1  mx-1  bold-text">Business Setup Guide</p>
        <br></br>
        <div className="row ">
          <div
            className="col-lg-4 col-md-4 mt-2"
          // data-aos="fade-up-right"
          // data-aos-duration="3000"
          >
            <div className="back zoom ">
              <div className="border-class">
                <h2 className="bold-text h2">Why ?</h2>
                <br></br>

                <h5 className="h5">
                  Dubai and UAE have always encouraged new and foreign talent with comparatively relaxed foreigner-friendly business guidelines........
                </h5>
                <br></br>

                <Link to="/why" className="h5 link-dec">
                  Know more <span className="display-2 ">{">>>>"} </span>{" "}
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-4 mt-2"
          // data-aos="fade-up"
          // data-aos-duration="3000"
          >
            <div className="back zoom">
              <div className="border-class">
                <h2 className="bold-text h2">Where ?</h2>
                <br></br>

                <h5 className="h5">
                  Venturing into trading, especially in a foreign country can be daunting, but with DBS on your side ..........
                </h5>
                <br></br>

                <Link className="h5 link-dec" to="/where">
                  Know more <span className="display-2">{">>>>"} </span>{" "}
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-4 mt-2"
          // data-aos="fade-up-left"
          // data-aos-duration="3000"
          >
            <div className="back zoom">
              <div className="border-class">
                <h2 className="bold-text h2">How ?</h2>
                <br></br>

                <h5 className="h5">
                  Venturing into trading, especially in a foreign country can be daunting, but with DBS on your side ...........
                </h5>
                <br></br>

                <Link to="/how" className="h5 link-dec">
                  Know more <span className="display-2">{">>>>"} </span>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </div>





      {/* <div className="container-fluid bg-dark ">
        <div className="mx-5 mt-2">
          <br></br>
          <h1 className="bold-text text-white h1 ">Testimonials</h1>
          <br></br>
          <h5 className="h5 text-white">
            We regard the client testimonial as a treasured token of
            appreciation that signifies our value as a Business Setup service
            provider. As you all know credible testimonials build “Trust” – one
            of the core business virtues that we cherish.
          </h5>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <Swiper
          spaceBetween={30}
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          breakpoints={{
            576: {
              // width: 576,
              slidesPerView: 1,
            },
            768: {
              // width: 768,
              slidesPerView: 2,
            },
          }}
          name="mySwiper"
          className="mx-5"
        >
          <SwiperSlide>
            <div
              className="center3 hover-underline-animation bg-white mx-3 rounded"
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "3%",
                paddingBottom: "0%",
              }}
            >
              <p className=" ">
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
              </p>
              <br></br>
              <div className="d-flex">
                <img src={avatar} alt="error" className="avatar" />
                <div className="mx-3 mt-3">
                  {" "}
                  <p className="bold-text ">
                    Mr.Jhon deo <br></br>{" "}
                    <span className="h5 text-secondary">
                      Manager of Holidng Group
                    </span>
                  </p>
                </div>
              </div>
              <br></br>
              <br></br>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <div
              className="center3 hover-underline-animation bg-white mx-3 rounded"
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "3%",
                paddingBottom: "0%",
              }}
            >
              <p className=" ">
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
              </p>
              <br></br>
              <div className="d-flex">
                <img src={avatar} alt="error" className="avatar" />
                <div className="mx-3 mt-3">
                  {" "}
                  <p className="bold-text ">
                    Mr.Jhon deo <br></br>{" "}
                    <span className="h5 text-secondary">
                      Manager of Holidng Group
                    </span>
                  </p>
                </div>
              </div>
              <br></br>
              <br></br>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <div
              className="center3 hover-underline-animation bg-white mx-3 rounded"
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "3%",
                paddingBottom: "0%",
              }}
            >
              <p className=" ">
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
              </p>
              <br></br>
              <div className="d-flex">
                <img src={avatar} alt="error" className="avatar" />
                <div className="mx-3 mt-3">
                  {" "}
                  <p className="bold-text ">
                    Mr.Jhon deo <br></br>{" "}
                    <span className="h5 text-secondary">
                      Manager of Holidng Group
                    </span>
                  </p>
                </div>
              </div>
              <br></br>
              <br></br>
            </div>
          </SwiperSlide>
        </Swiper>
        <br></br>
        <br></br>
      </div> */}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};

export default Services;
