import React, { useEffect } from "react";
import img from "../../Images/investor.png";
import SmallStrip from "../../components/SmallStrip";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const InvestorVisa = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Investor Visa </title>
        <meta name="description" content="All license here" />
      </Helmet>
      <SmallStrip data="Dubai-Investor-Visa" data2="Visa" />

      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4">
            <center>
              <img src={img} alt="error" className="img-fluid rounded margin-top4" />
            </center>
          </div>
          <div className="col-md-6 col-lg-7 col-xl-8">
            <p className="h3 bold-text">Dubai Investor Visa, Dubai</p>
            <p className="font-size-para">
              While UAE, has a flourishing economy with a favorable business nurturing environment, Dubai is the emirate most people choose to finally pursue investment opportunities.  UAE presents such individuals with a chance to acquire a visa belonging to a different category – the Dubai Investor Visa. Anyone interested in either investing in an already existing business; or starting a venture of their own in the mainland or free zones can apply for this visa, also known as a Dubai partner visa. The visa validity often is dependent on the amount a person invests in the country, so the applicants who get it can also live in Dubai while they work with the added benefit of sponsoring family members. The investor visa is also an aid used by the government to standardize its intake of immigrant investors in the UAE, and hence, you also have to give certain documents to the Dubai Immigration Department when applying for the same. Another advantage of having an investor status in Dubai is how much easier it gets to apply for visas to visit other GCC countries. We have noticed that clients who come to us are usually new to the process of starting a business or moving to the UAE and understandably apprehensive and clueless about the official processes involved. We at Diplomat Documents Services (DDS) want our clients to rest assured as we will be with them every step of the way and available for any queries whatsoever, they may have about Dubai Investor Visa.
            </p>
            <Link to="/contact" className="button-animation">
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>

      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">Some Fundamental Formalities when setting up a business in Dubai!</p>
        <br></br>
        <div className="row">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-6 col-lg-4   mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className=" bold-text d-flex" style={{ textTransform: "capitalize" }} >HOW TO BECOME AN INVESTOR IN DUBAI? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2" >Diplomat Documents Services (FBS) offers uncomplicated processing for application to become an investor and to attain the much-coveted Dubai Investor Visa. We understand the industry, and the legal technicalities involved well enough to not just represent your interest but also to direct you in the right direction to become an investor as promptly and competently as possible in Dubai or any other emirate in the UAE.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex" style={{ textTransform: "capitalize" }} >ONCE I RECEIVE A SINGLE DUBAI INVESTOR VISA, CAN I GET A BUSINESS PARTNER?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Yes, you can find a business partner once you have received a Dubai investor visa as several business partners are allowed to work under a single Investor Visa in Dubai. But the investment amount cannot be split among each of you and DDS recommends that each business partner has to invest a minimum of about AED 10 million </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">WILL THE INVESTOR VISA APPLICATION PROCESS TAKE LONG?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Though the application process for an investor visa may extend to a period of about 20 days wherein they check the potential investor’s background and finally issue the Entry Permit. FBS helps to shorten the process, by making sure all the necessary documents are submitted at the right time and that all the rules and criteria are correctly met for a smooth application process. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">WHAT IS THE VALIDITY PERIOD FOR AN INVESTOR VISA IN DUBAI AND CAN I WORK WHEN I GET IT?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">The Investor Visa has a validity of about three years during which the holder of the visa can both live and work in Dubai or anywhere in the UAE. If you like the country and want to stay longer DDS recommends you apply for a 5-year or longer 10-year residency visa. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">CAN THE DUBAI INVESTOR VISA BE EXTENDED TO INVOLVE DEPENDENTS?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Major benefits of receiving the Dubai investor visa include sponsorship, wherein you bring your closest family members like your spouse, children, and parents to live with you with the added advantage of allowing them to work in the country if they should wish to do so. The visa can also be extended to an executive director and advisor from your company. </p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
      <br></br>
      <br></br>
      <br></br>
      <hr></hr>
    </>
  );
};

export default InvestorVisa;
