import React, { useEffect } from "react";
import img from "../../../Images/b12.png";

import SmallStrip from "../../../components/SmallStrip";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Industrial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Industrial License</title>
        <meta name="description" content="All license here" />
      </Helmet>

      <SmallStrip data="Industrial" data2="Licensing" />


      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-3 col-lg-5 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded margin-top4" />
          </div>
          <div className="col-md-9 col-lg-7 col-xl-8">

            <p className="h3 bold-text ">
              Industrial License in Dubai, UAE
            </p>

            <p className="font-size-para">
              In the middle east, Dubai serves as a world-class hub for both business and industry and hence ends up doing an excellent job of connecting the East to the West. This city never fails to amaze anyone who has seen it because of its awe-inspiring infrastructure, and presence of modern amenities, but most of all having one of the most superior trade routes favorable to easy access commercial activities between the UAE and Europe, Asia, and even Africa. This makes UAE one of the prime industrial business-related destinations. An industrial license should be obtained for those interested in starting a company or running a business related to the industrial world in the UAE. In Dubai mainland, this license is issued by the Department of Economic Development (DED) and is often a fundamental start to one’s company formation. It is considered to be the official permit for anyone looking to run a production or manufacturing-based business legally in the UAE. One of the best parts of having obtained an industrial license is how it encompasses not just production but also manufacturing-type activities as well. While production usually refers to all the processes involved in transforming natural resources or raw materials from the crude or initial stage to a refined or final stage, along with production and manufacturing, industrial aggregation, segregation, and final packaging. We at Diplomat Documents Service  (DDS) look forward to helping all our clients from the very start of their business venture, verifying the presence of all necessary documents, and understanding what all licenses, permits, etc they may require for their company based on the activities they have in mind to helping them navigate the various rules and regulations of both DED and the UAE government to finally see them starting in the right manner inclusive of a corporate banking account.
            </p>


            <Link to="/contact" className="button-animation">
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">FREQUENTLY ASKED QUESTIONS</p>
        <br></br>
        <div className="row">

          <div className="col-md-4  mt-2">
            <div className="card-faq shadow" style={{ height: "100%" }}>
              <div className="mx-4">
                <p className="bold-text d-flex"> WHAT IS THE RELEVANCE OF AN INDUSTRIAL LICENSE IN THE UAE? </p>
                <p>The industrial license in the UAE is for those interested in setting up companies focused on industrial business activities anywhere in UAE. The business activities that need an industrial license include manufacturing from imported raw materials, assembling, packaging, transporting, and even exporting finished products. DBS ensures an easy issuance of the license for the production and trading of products made within the UAE or internationally. </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="card-faq shadow" style={{ height: "100%" }}>
              <div className="mx-4">
                <p className="bold-text d-flex" >HOW TO OBTAIN AN INDUSTRIAL LICENSE IN DUBAI MAINLAND? </p>
                <p>While the requirements are many for the obtainment of an industrial license in Dubai, one of the most important ones is the need for a physical premise as virtual offices are not permitted. Also, you must have a local industrial license from your business area, especially if you choose the free zone as the free zone authorities vary according to the area in which your company operates. </p>
              </div>
            </div>
          </div>
          <div className="col-md-4  mt-2">
            <div className="card-faq shadow" style={{ height: "100%" }}>
              <div className="mx-4">
                <p className="bold-text d-flex"> WHAT ARE THE REQUIREMENTS FOR AN INDUSTRIAL LICENSE IN DUBAI? </p>
                <p>Diplomat Documents Service  (DDS) ensures you are aware of exactly how many visas you can apply for when opening a business in mainland Dubai. While there are no direct restrictions against the number of visas, every 9 sqm of office space allows the allotment of about one visa. Therefore, the bigger the office space more the visas your company may receive. </p>
              </div>
            </div>
          </div>
          <div className="col-md-4  mt-2">
            <div className="card-faq shadow" style={{ height: "100%" }}>
              <div className="mx-4">
                <p className="bold-text d-flex">WHAT ARE SOME PRIVILEGES OF HAVING AN INDUSTRIAL LICENSE? </p>
                <p>The firms with an industrial license are afforded certain privileges to help promote industries and thereby attract a lot more attention and investments in this sector. The privileges include the provision of land by the government at a subsidized rate for the establishment of industry, Discounted rates for essential requirements inclusive of electricity and water, and often even guidance to help enhance profits. </p>
              </div>
            </div>
          </div>
          <div className="col-md-4  mt-2">
            <div className="card-faq shadow" style={{ height: "100%" }}>
              <div className="mx-4">
                <p className="bold-text d-flex"> HOW CAN DBS LLC HELP WITH THE APPLICATION FOR AN INDUSTRIAL LICENSE? </p>
                <p>While setting up an industrial company in Dubai may be a very daunting venture for most foreigners, DDS makes it easy to achieve through its diligence and of course experience in helping entrepreneurs stand on their own.  We make sure your documents are in order, and also take care of legal technicalities on your behalf while we apply for your industrial license from the DED.  </p>
              </div>
            </div>
          </div>



        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </div>


    </>
  );
};

export default Industrial;
