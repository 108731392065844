import React, { useEffect } from "react";


import img2 from "../../Images/offshore.png";

import SmallStrip from "../../components/SmallStrip";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const OffShore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>OffShore</title>
        <meta name="description" content="All license here" />
      </Helmet>
      <SmallStrip data="OffShore" data2="Licensing" />

      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4">
            <img src={img2} alt="error" className="img-fluid rounded margin-top4" />
          </div>
          <div className="col-md-6 col-lg-7 col-xl-8">
            <p className="h3 bold-text ">
              Offshore Company Formation in Dubai,UAE
            </p>


            <p className="font-size-para">
              Dubai has always been the emirate of choice when it comes to starting a business or investing in one. The emirate has an admirable business landscape, which may be due to its strategic location, thriving economy, and last but not least the incredibly alluring tax regime. An interesting feature to note is how Dubai welcomes businesses of any type, be it the type that requires a traditional, commercial, industrial, or professional license, or even the sort that may need more specific setups. International entrepreneurs are into investing in the emirate’s free zones as there are no currency restrictions, complete tax exemption, and 100% recovery of both capital and profit. Notably, some free zones, such as the Jebel Ali Free Zone (JAFZA) or the Ras Al Khaimah Free Trade Zone (RAK FTZ) offer offshore trading as well. The offshore company setup may seem simple but can be complicated if there is a lack of in-depth knowledge of UAE’s customs, laws, and regulations. Hence Future Diplomat Documents Services (DDS) would love to step in and help those interested to make the right choices based on their needs and budget. Choosing us ensures that both license and visa applications have all documents in order and are free from errors or omissions which may lead to delays or rejection from the government. Once you have given us your business idea along with information regarding the nature of the business you have in mind, we help you decide the right course of events that lead to the successful setting up of your company with zero time wastage, complete convenience, and repatriating maximum profit.

            </p>

            <Link to="/contact" className="button-animation">
              Contact us
            </Link>



            <br></br>
            <br></br>
          </div>
        </div>
        <div className="row mt-5">
          <center>
            <p className="h2 bold-text ">
              Offshore
            </p>
          </center>
          <p className="mt-1 font-size-para">
            Offshore companies are very rewarding of investments and company setups if not being tied down to a country are important. The workings of this type of company will never have legal restrictions or tax requirements of the UAE. Also, one can stay in one’s own country when having an offshore company as compared to the mainland company which requires the presence of the owner at the location of trade set up.
          </p>

        </div>
      </div>
      <br></br>

      <br></br>
      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">Some Fundamental Formalities when setting up a business in Dubai!</p>
        <br></br>
        <div className="row">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-6 col-lg-4   mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className=" bold-text d-flex" style={{ textTransform: "capitalize" }} >HOW IS A DUBAI OFFSHORE COMPANY DIFFERENT FROM A FREE ZONE SETUP? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2" >Some of the major differences between a Dubai offshore company and a Dubai Free zone company are the following: A Dubai offshore company is not permitted to have a physical office in the UAE, also it is prohibited from undertaking any business activities within the country. Offshore company owners or shareholders in their company are not eligible to apply for residency visas in UAE.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex" style={{ textTransform: "capitalize" }} >WHAT ARE THE BENEFITS OF A DUBAI OFFSHORE COMPANY SETUP?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">One of the noted benefits of operating a Dubai offshore company is the anonymity it affords its investors as the details of its owners and shareholders are inaccessible to the public. All offshore setups are benefitted from UAE’s incredibly low tax regime, zero taxation on corporate and personal income, are not required to pay share capital, and are quite an affordable investment.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex"> WHAT ARE THE BUSINESS ACTIVITIES PERMITTED TO THE OFFSHORE COMPANY?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">The few activities permitted to Offshore companies include holding stakes in other businesses, and purchasing and managing real estate. and hold stakes in other businesses among other similar activities. Advisory or consultancy services, management of wealth, and some types of brokerage services. DBS has detailed info regarding the permitted activities and can help you choose the activity best suited to your investment interest and capital.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">WHAT ARE THE DOCUMENTS NEEDED TO START AN OFFSHORE COMPANY IN DUBAI?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">While many documents need to be submitted with the application for an offshore license, the most important are:<br></br>
                      1.	A copy of a valid passport for each of its shareholders.<br></br>
                      2.	Bank statements that go back at least six months.<br></br>
                      3.	financial documents that have been attested.<br></br>
                      Other documents needed or legal formalities to be navigated can be done so by the DBS.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">Corporate Banking</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Corporate banking is one of the most integral formalities that need to be dealt with when one opts to establish one’s startup in Dubai. It can also be complicated when trying to open a bank account on your own. Hence, DDS offers all the banking assistance one may require from starting a banking account for corporate purposes to even managing the same once the company has been set up.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

      <br></br>

      <hr></hr>
    </>
  );
};

export default OffShore;
