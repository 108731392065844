import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Contact from "../../components/Contact";
import SmallStrip from "../../components/SmallStrip";
import img from "../../Images/b6.png";

const Why = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Why Start Business in Dubai?</title>
        <meta name="description" content="All license here" />
      </Helmet>
      <div className="">
        <SmallStrip data="Why" data2="Business-Guide" />

        <div className="container-fluid padding3 marginTop-500">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xl-4">
              <img src={img} className="img-fluid rounded margin-top4" alt="error" />
            </div>
            <div className="col-lg-8 col-md-6 col-xl-8">
              <p className="h3 bold-text">
                Why Start Business in Dubai?
              </p>
              <p className="mt-3 font-size-para"> Dubai and UAE have always encouraged new and foreign talent with comparatively relaxed foreigner-friendly business guidelines. There are too many reasons why most immigrants choose to not just start a trade here but also in time become residents in this beautiful country. Having world-class infrastructure, an incredible and rich economy, being home to a very skilled workforce, most amenities under a single umbrella, and a rich yet cosmopolitan culture are some of the factors that fascinate non-local investors. Dubai has changed slowly into a technological and innovation capital that attracts millions in droves to invest and gain tremendous returns.</p>
              <Link to="/contact" className="button-animation">
                Contact us
              </Link>
            </div>
          </div>
          <br></br>

          <br></br>

          <br></br>

        </div>


      </div>
      {/* <div className="back-img-5">


        <div className="row center ">
          <p className="h1  mx-1  bold-text">Contact Us</p>

        </div>
      


        <br></br>
      </div> */}

      <Contact />
      <br></br>
      <br></br>

    </>
  );
};

export default Why;
