
import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { auth } from "../FirebaseConfig.js"
import { Button, Container, Form, InputGroup } from 'react-bootstrap';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setShow(!show);
    };
    const handleLogin = () => {

        // e.preventDefualt();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user)
                localStorage.setItem("user", JSON.stringify(user));
                navigate("/adminpanel/aboutus");
                // ...
            })
            .catch((error) => {
                // const errorCode = error.code;
                // const errorMessage = error.message;
                alert(error);
                setError(error);
            });
    }


    return (
        <div className='bg-color' style={{ height: "100vh" }}>
            <br></br>
            <br></br>

            <Container mt={20} mb={10} centerContent>
                <center>
                    <div
                        display="flex"
                        justifyContent="center"
                        bg={"white"}

                        m="40px 0 15px 0"

                        style={{ marginBottom: "2rem", backgroundColor: "white", width: "22rem", padding: "0.4rem", borderRadius: 10 }}
                        borderRadius="lg"
                        borderWidth="1px"
                    >
                        <center color={"white"} fontFamily="Poppins">
                            <h3 > Login Page  </h3>
                        </center>
                    </div>

                    <center>
                        <div style={{ width: "22rem", background: "white", padding: "0.5rem", borderRadius: 10 }}  >
                            {/* colorScheme="green" */}
                            <center>
                                <center fontFamily="Poppins">
                                    {error && <p style={{ color: "red" }}>wrong password and email</p>}
                                </center>
                                <Form.Group className="input-fluid " controlId="formName">
                                    {/* <p className="paragraph-small">Email:</p> */}
                                    <InputGroup className=" mt-3">
                                        <Form.Control
                                            style={{ padding: "15px" }}
                                            placeholder="Enter Your Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>


                                <Form.Group className="input-fluid" controlId="formName">
                                    {/* <p className="paragraph-small">Password:</p> */}
                                    <InputGroup className=" mt-3">
                                        <Form.Control
                                            type={show ? "text" : "password"}
                                            style={{ padding: "15px" }}
                                            value={password}
                                            placeholder="Enter Your password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div width="4.5rem" className='mt-2' >
                                            <Button h="1.75rem" size="sm" mt="3" style={{ background: "none", border: "none" }} onClick={handleClick}>
                                                {show ? (
                                                    <AiOutlineEyeInvisible fontSize="25px" color="grey" />
                                                ) : (
                                                    <AiOutlineEye fontSize="25px" color="grey" />
                                                )}
                                            </Button>
                                        </div>
                                    </InputGroup>

                                </Form.Group>

                                <Button
                                    className="button2 mt-4"
                                    width="100%"
                                    style={{ marginTop: 15 }}
                                    onClick={handleLogin}
                                >
                                    Login
                                </Button>
                            </center>
                        </div>
                    </center>
                </center>
            </Container>


        </div >
    )
}

export default Login
