import React, { useState } from "react";
import { FaBars } from "react-icons/fa";

import {
    SidebarData,

} from "./SideBarData";
import SubMenu from "./SubMenu";
import './admin.css'

const SideBar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    // const [subnav, setSubnav] = useState(false);
    // const showSubnav = () => setSubnav(!subnav);

    return (
        <div
            style={{ width: isOpen ? "200px" : "60px" }}
            className="sidebar"
        >
            <div className="top_section">
                <h1
                    style={{ display: isOpen ? "block" : "none" }}
                    className="font-size2"
                >
                    DDS
                </h1>
                <div style={{ marginLeft: isOpen ? "70px" : "0px" }} className="bars">
                    <FaBars onClick={toggle} />
                </div>
            </div>

            <p className="small-text mt-4">MENU </p>
            {SidebarData.map((item, index) => {
                return (
                    <SubMenu
                        item={item}
                        key={index}
                        style={{ display: isOpen ? "inline" : "none" }}
                        isOpen={isOpen}
                        className="overflow-hidden"
                    />
                );
            })}


            <main>{children}</main>
        </div>
    );
};

export default SideBar;