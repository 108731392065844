import React from "react";

import "./need.css";


import aboutus from "../Images/aboutus.png"
import patners from "../Images/patners.png"
import { Link } from "react-router-dom";

const Needs = () => {
  // const [showComponent, setshowComponent] = useState("start");


  return (
    <>
      <img src={patners} className="img-fluid" alt="error" />
      <div className="container-fluid padding2 marginTop-500">
        {/* <div className="row center">
          <p className="h3 bold-text">About Us</p>
        </div> */}
        <div className="row mt-5">
          <div className="col-md-4">
            <img src={aboutus} className="img-fluid rounded mt-2" alt="error" />
          </div>
          <div className="col-md-8">
            <p className="h4 bold-text" >Diplomat Documents Services  </p>
            <p className=" mt-2" >
              Do you plan to set a company up in Dubai or the UAE, but are unsure of how to proceed? Our professional team at DDS is more than happy to put our experience to good use when we help you at the very outset to finally be set in the venture to start a business here in Dubai. We could help you find a reliable Emirati sponsor if required, apply for the license most suitable to your business activity, as well any other permits you may require during the process. To best maximize your profit margin, a good idea of all the pros and cons of the three main types of licenses (Mainland, Free Zone, and Offshore) in Dubai is essential and hence our guidance in this sector could just be what you require in the circumstances. Partnering with us to help set you up with the company that you want in the place of your dreams is the best decision you make today.
            </p>

            <Link to="/about" className="button-animation mt-2">
              Know More
            </Link>
          </div>
        </div>
      </div>
      <br></br>



    </>
  );
};

export default Needs;
