import "./App.css";
import Navbar from "./components/Navbar";
import { Navigate, createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Where from "./pages/BusinessGuide/Where";
import How from "./pages/BusinessGuide/How";
import Why from "./pages/BusinessGuide/Why";
import Mainland from "./pages/Licensing/Mainland";
import FreeZone from "./pages/Licensing/FreeZone";
import OffShore from "./pages/Licensing/OffShore";
import Commericial from "./pages/Licensing/MainLand/Commericial";
import Etrade from "./pages/Licensing/MainLand/Etrade";
import Industrial from "./pages/Licensing/MainLand/Industrial";
import Professional from "./pages/Licensing/MainLand/Professional";
import Tourism from "./pages/Licensing/MainLand/Tourism";
import BusinessVisa from "./pages/Visa/BusinessVisa";
import InvestorVisa from "./pages/Visa/InvestorVisa";
import About from "./pages/About";
import Login from "./adminpanel/Login";
import AboutUs from "./adminpanel/AboutUs";
import Users from "./adminpanel/Users";
import Scroll from "./components/Scroll";
import SideBar from "./adminpanel/Sidebar";
import Footer from "./components/Footer";
import ContactPage from "./pages/ContactPage";

// import SocialBAr from "./components/SocialBAr";
// import Login from "./adminpanel/Login";
// import AdminPanel from "./adminpanel/AdminPanel";
// import AddBlog from "./adminpanel/AddBlog";
// import SingleBlog from "./adminpanel/SingleBlog";
// import Users from "./adminpanel/Users";

// import Header from "./components/Header";

let cureentUser = false;

const RequireAuth = ({ children }) => {

  const currentFont = localStorage.getItem('user');
  if (currentFont) {
    cureentUser = true;
  }
  else {
    cureentUser = false;
  }
  return cureentUser ? children : <Navigate to="/login" />
}

const Layout = () => {
  return (
    <>
      <Navbar />
      {/* <SocialBAr /> */}
      {/* <Scroll /> */}
      <Outlet />
      <Footer />
    </>
  )
}



const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/where',
        element: <Where />,
      },
      {
        path: '/how',
        element: <How />,
      },
      {
        path: '/why',
        element: <Why />,
      },
      {
        path: '/mainland',
        element: <Mainland />,
      },
      {
        path: '/freezone',
        element: <FreeZone />,
      },
      {
        path: '/offshore',
        element: <OffShore />,
      },
      {
        path: '/commericial',
        element: <Commericial />,
      },
      {
        path: '/etrade',
        element: <Etrade />,
      },
      {
        path: '/industrial',
        element: <Industrial />,
      },
      {
        path: '/professional',
        element: <Professional />,
      },
      {
        path: '/tourism',
        element: <Tourism />,
      },
      {
        path: '/businessvisa',
        element: <BusinessVisa />,
      },
      {
        path: '/investorvisa',
        element: <InvestorVisa />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/contact',
        element: <ContactPage />,
      },
    ]

  },
  {
    path: '/login',
    element: <Login />
  },
  // {
  //   path: '/adminpanel/update/:id',
  //   element: (<SingleBlog />),
  // },
  // {
  //   path: '/adminpanel',
  //   element:
  //     (
  //       <div style={{ display: "flex" }}>
  //         <SideBar />
  //         <RequireAuth><AdminPanel /></RequireAuth>
  //       </div>
  //     ),
  // },

  {
    path: '/adminpanel/users',
    element: (
      <div style={{ display: "flex" }}>
        <SideBar /><RequireAuth> <Users /></RequireAuth> </div>),
  },
  {
    path: '/adminpanel/aboutus',
    element: (
      <div style={{ display: "flex" }}>
        <SideBar /><RequireAuth> <AboutUs /></RequireAuth> </div>),
  }

])




function App() {
  // console.warn = () => { };
  return (
    <div className="App">
      <RouterProvider router={router} />
      {/* <Routes>
        <Route path="/adminPanel" element={<RequireAuth> <AdminPanel /></RequireAuth>} />

        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/where" element={<Where />} />
        <Route path="/how" element={<How />} />
        <Route path="/why" element={<Why />} />
      
        <Route path="/mainland" element={<Mainland />} />
        <Route path="/freezone" element={<FreeZone />} />
        <Route path="/offshore" element={<OffShore />} />
        <Route path="/commericial" element={<Commericial />} />
        <Route path="/etrade" element={<Etrade />} />
        <Route path="/industrial" element={<Industrial />} />
        <Route path="/professional" element={<Professional />} />
        <Route path="/tourism" element={<Tourism />} />
        <Route path="/businessvisa" element={<BusinessVisa />} />
        <Route path="/investorvisa" element={<InvestorVisa />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
      </Routes> */}

      {/* <Footer /> */}
    </div>
  );
}

export default App;
