import React from "react";

import { MdOutlineDashboard } from "react-icons/md";

import { FaUserAlt } from "react-icons/fa";

export const SidebarData = [
    {
        title: "About",
        path: "/adminpanel/aboutus",
        icon: <MdOutlineDashboard />,


    },
    {
        title: "User",
        path: "/adminpanel/users",
        icon: <FaUserAlt />,


    },
    // {
    //     title: "Blogs And Add blog",
    //     path: "/adminpanel/addblog",
    //     icon: <AiFillFolderAdd />,


    // },



];
