import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Contact from "../components/Contact";
const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (<>

    <Helmet>
      <title>Contact US</title>
      <meta name="description" content="All license here" />
    </Helmet>
    <div>

      <div className="container mt-5">

        {/* <div className="row"> */}


        <center>
          <div >
            <h1 className="text-dark bold-text"> HAVE SOME QUESTIONS?</h1>
            <p className="text-secondary">Let's chat Tell me about your dream company and project</p>
          </div>
        </center>


        {/* </div> */}
      </div>



      <br></br>
      <Contact />

    </div >
    <br></br>
    <br></br>
    <br></br>
  </>
  )
};

export default Blog;
