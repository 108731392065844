import React, { useEffect } from "react";
import img from "../../../Images/8.png";
import SmallStrip from "../../../components/SmallStrip";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Commericial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Commercial License</title>
        <meta name="description" content="All license here" />
      </Helmet>

      <SmallStrip data="Commericial" data2="Licensing" />

      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-3 col-lg-5 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded margin-top4" />
          </div>
          <div className="col-md-9 col-lg-7 col-xl-8">
            <p className="h3 bold-text ">Commercial License in Dubai</p>
            <p className="font-size-para">

              Being a part of the trading community in Dubai or any other part of the UAE requires one to have acquired a commercial license especially when their business activity involves the selling or trading of products. The commercial license is different from the general trading license in that it is more specific and includes a limited number of products. While general trading covers most products, automobiles, or software that need a commercial license for their trade to be registered and progress in Dubai. Hence choosing to apply for a commercial license is suitable for both general trading companies as well as specialized trading companies across the UAE.  Trading of not just goods or commodities but also services need the issuance of a commercial license for smooth processing and establishment in Dubai. Other businesses that necessitate application for a commercial trade license in UAE include those related to retail, real estate, and construction industries. The best part of trying for a commercial license is how it not only regulates the trading company but is also a form of protection. Approval for all commercial activities is given by the Department of Economic Development (DED). When registering, while the choices for the type of company are many even international, several similar business activities can be conducted under a single license, and a minimum of ten trading activities come under one commercial trade license. This license allows for the easy hiring of foreign workers. If a business involves many particular activities, a commercial license may not suffice, also if the activities are covered under more than a single license, additional licenses and special permits or authorization need to be applied for.

            </p>


            <Link to="/contact" className="button-animation">
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">FREQUENTLY ASKED QUESTIONS</p>
        <br></br>
        <div className="row">
          {/* <div className="col-md-4 mt-2">
            <p className="h4 d-flex">A FAQ  is a list </p>
            <p className="">A FAQ  is a list of common questions and answers that provide information about a particular subject, product, service, or company. The purpose of a FAQ is to provide quick and easily accessible information to users, reducing the need for them to contact support or perform extensive research A FAQ  is a list of common questions and answers that provide information about a particular subject, product, service, or company. The purpose of a FAQ is to provide quick and easily accessible information to users, reducing the need for them to contact support or perform extensive research.</p>
          </div> */}
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className=" bold-text d-flex"> WHAT DO YOU MEAN BY COMMERCIAL LICENSE IN DUBAI OR THE UAE?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Having acquired a commercial license in Dubai allows one to trade in a huge range of goods and services that include even importing and exporting within the UAE. Also permissive to the sale of many types of goods like food and drinks, clothes, toys, and so much as appliances. Other services this license caters to include brokering and real estate. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex"  >WHAT ARE THE BASIC BUSINESS ACTIVITIES THAT COME UNDER THE COMMERCIAL LICENSE? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2">The list is extensive with regard to all the services one’s business can offer once they have acquired the commercial license. Trading in anything and everything from advertising, car rental or even banking to stationary or waste management is possible. DDS offers to provide a detailed list of all the activities approved by the DED to those interested.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex"> WHAT ARE THE BENEFITS OF ACQUIRING A COMMERCIAL LICENSE? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2">The popularity of choosing to obtain a commercial license is because once one has acquired this license, one is free to trade in just about anything especially as the international airports in the UAE help make Dubai a most convenient and strategic location for global trading. DDS helps with the steps you need to trade in compliance with UAE business laws. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">UAE Branch Office Set-Up</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Setting up a business in UAE can be done in many ways, but methods to retain complete ownership while doing so are trickier. Hence opening the branch office to an already existing company is often a popular choice among immigrant entrepreneurs. DDS is able to help guide you through all the legal paperwork and hassles if this is how you plan to set up an office.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">HOW CAN DBS LLC HELP TO ACQUIRE THE COMMERCIAL LICENSE?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">DDS helps you navigate all the latest regulations that may otherwise seem complicated. As we’re highly experienced in not just helping you set your company up but to do so in a time-effective manner. Just a few weeks’ time is enough for us to sail you through the entire process and you can start your trading as soon as possible.  </p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </div>

    </>
  );
};

export default Commericial;
