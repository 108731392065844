import React, { useState, useRef } from "react";
import "./contact.css";

import { db } from "../FirebaseConfig";
import { uid } from "uid";
import { set, ref, serverTimestamp } from "firebase/database";
import { Form, InputGroup, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { BsFillTelephoneInboundFill } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import PhoneInput from 'react-phone-input-international'
import 'react-phone-input-international/lib/style.css'
import { AiOutlineWhatsApp } from "react-icons/ai";



const Contact = () => {
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [mess, setMess] = useState("");
  const [message, setMessage] = useState({ error: false, msg: "" });


  console.log(phone);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(phone);

    emailjs
      .sendForm(
        "service_4yk79ob",
        "template_twcdqnt",
        form.current,
        "5VqSr2F90bBmCjG8a"
      )
      .then(
        (result) => {
          console.log(result.text);
          setName("");
          setLastname("");
          setPhone("");
          setEmail("");
          setMess("");
          // window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleSubmit = async (e) => {
    const uuid = uid();
    e.preventDefault();
    setMessage("");
    if (
      name === "" ||
      lastname === "" ||
      phone === "" ||
      email === "" ||
      mess === ""
    ) {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    } else {
      set(ref(db, `/${uuid}`), {
        name: name,
        lastname: lastname,
        phone: phone,
        email: email,
        mess: mess,
        timeStamp: serverTimestamp(),
      });

      setMessage({ error: false, msg: "Contact Submit successfully" });
    }

    setName("");
    setLastname("");
    setPhone("");
    setEmail("");
    setMess("");
  };

  const handleSubmitData = (e) => {
    handleSubmit(e);
    sendEmail(e);
  };

  return (
    <>
      <div className="container bg-white mt-4 rounded shadow2"  >
        <br></br>
        <br></br>
        {/* {message?.msg && (
          <Alert
            variant={message?.error ? "danger" : "success"}
            dismissible
            onClose={() => setMessage("")}
          >
            {message?.msg}
          </Alert>
        )} */}
        <div className="row">

          <div className="col-md-5">
            {/* <img src={aboutus} className="img-fluid mt-3"></img> */}
            <p className="maps mt-2 mx-2"  ><iframe title='demo' style={{ width: "100%", height: "10rem", border: "0", borderRadius: 10, allowfullscreen: "", loading: "lazy" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4171.969212461!2d55.385265000000004!3d25.264207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5da287a20f23%3A0x41a2a8197736e21f!2sSharjah%20Islamic%20Bank%2C%20Al%20Twar%20Branch!5e1!3m2!1sen!2sin!4v1677872177680!5m2!1sen!2sin" ></iframe></p>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9025.238507712007!2d73.12579754368163!3d19.236387869628214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7954a866ebbd1%3A0xe9010fdbbc8d4393!2sD-Mart!5e1!3m2!1sen!2sin!4v1675111883745!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4171.969212461!2d55.385265000000004!3d25.264207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5da287a20f23%3A0x41a2a8197736e21f!2sSharjah%20Islamic%20Bank%2C%20Al%20Twar%20Branch!5e1!3m2!1sen!2sin!4v1677872177680!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

            <div className="mx-3 mt-4">
              <p className="text-dark   d-flex" style={{ marginTop: "-5px" }} ><BsFillTelephoneInboundFill className="h3  mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /> <span className="bold-text"> Main Office: </span>  +971 4 326 2732 </p>
              <p className="text-dark    d-flex" style={{ marginTop: "-5px" }} ><BsFillTelephoneInboundFill className="h3  mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /> <span className="bold-text">Branch Office: </span> +971 4 251 0444  </p>
              <p className="text-dark  d-flex" style={{ marginTop: "-10px" }}  ><AiOutlineWhatsApp AiOutlineWhatsApp className="h3  mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /><span className="bold-text">Whatsapp: </span> +971 50 887 8699</p>
              <p className="text-dark   d-flex" style={{ marginTop: "-10px" }}  ><BsFillTelephoneInboundFill className="h3  mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /> <span className="bold-text">Phone: </span> +971 52 895 1898</p>
              <p className="text-dark bold-text  d-flex" style={{ marginTop: "-5px" }} ><MdEmail className="h3 mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /> Info@diplomatdoc.com</p>
              <p className="text-dark  bold-text d-flex" style={{ marginTop: "-5px" }} ><HiLocationMarker className="h3 mx-1" style={{ background: "#00B4D8", padding: "4px", borderRadius: 22, color: "white" }} /> Office No:305,Coastal Building Al Twar - 2,<br></br> Near Sharjah Islamic Bank,Dubai P.O Box: 235548</p>
              <p className="text-dark bold-text  d-flex"> <HiLocationMarker className="h3 mx-1" style={{ background: "#00B4D8", padding: "4px", borderRadius: 20, color: "white" }} />Office No:G 21,Arzoo Real Estate Building<br></br> Al Twar - 2,  Near Sharjah Islamic Bank,Dubai P.O Box: 235548</p>
            </div>
          </div>
          <div className="col-md-7">
            <Form ref={form} onSubmit={handleSubmitData}>
              <div className="row">
                <div className="col-md-12 mt-2" style={{ padding: '2px' }}>
                  <Form.Group className="input-fluid" controlId="formName">
                    {/* <p className="paragraph-small">FirstName:</p> */}
                    <InputGroup className=" mt-2">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="FirstName"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

              </div>
              <div className="row">
                <div className="col-md-12 mt-2" style={{ padding: '2px' }}>
                  <Form.Group className="mb-3" controlId="formlast">
                    {/* <p className="paragraph-small">LastName:</p> */}
                    <InputGroup className=" mt-2">
                      <Form.Control
                        type="text"
                        name="lastname"
                        placeholder="LastName"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              <div className="row" style={{ marginTop: '-10px' }} >
                <div className="col-md-6 mt-2" style={{ padding: '2px' }}>
                  <Form.Group className="input-fluid mt-2" style={{ width: "100%" }} controlId="formPhone">
                    {/* <p className="paragraph-small">Phone:</p> */}
                    {/* <InputGroup className=" mt-2">
                      <Form.Control
                        type="number"
                        placeholder="Phone"
                        name="phone"
                        className="form-control"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </InputGroup> */}
                    <InputGroup className=" ">
                      <PhoneInput
                        country={'us'}
                        inputProps={{
                          name: 'phone',
                          required: true,
                        }}
                        containerStyle={{

                          marginLeft: "2px",
                          borderRadius: "10px"
                        }}
                        inputStyle={{
                          borderRadius: "10px",

                          width: "99%"
                        }}
                        name="phone"
                        value={phone}
                        style={{ width: "99%" }}
                        onChange={event => setPhone(event)}
                      />
                      {/* <Form.Control
                        style={{ color: "black", }}
                        placeholder="Phone"
                        name="phone"
                        type="number"
                        className=""
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                      /> */}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-6  mt-2" style={{ padding: '2px' }}>
                  <Form.Group className="input-fluid" controlId="formEmail">
                    {/* <p className="paragraph-small">Email:</p> */}
                    <InputGroup className=" ">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="form-control mt-2 "
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              {/* <div className="row ">

                <div className="col-md-12 mt-2">
                  <Form.Group className="input-fluid" controlId="formEmail">
                    <p className="paragraph-small">Email:</p>
                    <InputGroup className=" mt-2">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div> */}

              <div className="row ">
                <div className="col-md-12 mt-3" style={{ padding: '2px' }}>
                  <Form.Group className="input-fluid" controlId="formMessage">
                    {/* <p className="paragraph-small">Message:</p> */}
                    <InputGroup className=" mt-2">
                      <Form.Control
                        type="text"
                        name="mess"
                        placeholder="Message"
                        className="form-control"
                        value={mess}
                        onChange={(e) => setMess(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-5 text-dark">
                <p>
                  <input type="checkbox" name="" value="" className="mx-2 " />
                  By checking this box , I understand and acknowledge that the
                  information shared above is genuine to the best of my knowledge.
                </p>

                <br></br>
              </div>

              <div className="mx-1 mt-4">
                <Button
                  type="Submit"
                  className="button-animation"
                  style={{ width: "100%" }}
                >
                  SUBMIT
                </Button>
              </div>
            </Form>
          </div>
        </div>


      </div>

    </>
  );
};

export default Contact;
