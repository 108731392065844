import React, { useEffect } from "react";

import img from "../../Images/b5.png";

import SmallStrip from "../../components/SmallStrip";

import { Link } from "react-router-dom";
import Contact from "../../components/Contact";
import { Helmet } from "react-helmet";

// import "../../style.css";

const Where = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* back-img-where */}
      <Helmet>
        <title>Where to Start Business in Dubai?</title>
        <meta name="description" content="All license here" />
      </Helmet>
      <div className="">
        <SmallStrip data="Where" data2="Business-Guide" />

        <div className="container-fluid padding3 marginTop-500">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xl-4">
              <img src={img} className="img-fluid rounded margin-top4" alt="error" />
            </div>
            <div className="col-lg-8 col-md-6 col-xl-8">

              <p className="h3 bold-text ">
                Where to Start Business in Dubai?
              </p>
              <p className="font-size-para">
                As one of the busiest and biggest business hubs, the UAE is one of the elite members of the ever-propagating businesses around the world. Dubai is the emirate that shines like a beacon far and wide guiding tourists, investors, and entrepreneurs alike in droves toward a premier all-encompassing city. The UAE government’s market-friendly fiscal policies along with championing foreign investment, and innovation in all departments are the reason for this tremendous economic growth. Based on the type of business, an investor can choose a mainland company or from the free zone regional companies. Mainland companies can operate within the local market or even outside the UAE with no operational restrictions and receive their license from the Department of Economic Development (DED). Free zone companies are different as the type of business activities allowed within the free zone are limited to those licensed by the respective free zone authority. Also, the Free zone companies can only operate inside the Free Zone or outside UAE. Whereas Offshore companies are allowed only to conduct business outside the UAE and hence a physical presence in the Emirates is not necessary. Hence, when investing, “Where” is an important part of setting up a business in Dubai. It is here, that Diplomat Documents Services(DDS) comes to your service, we can guide your choices based on what you have in mind and help you weigh all the options available to you to take your company to its full potential based on what Dubai has to offer.
              </p>

              <Link to="/contact" className="button-animation">
                Contact us
              </Link>
            </div>
          </div>

        </div>
        <br></br>
        <br></br>
        <br></br>

      </div>
      <Contact />
      <br></br>
      <br></br>
      <br></br>
      <br></br>

    </>
  );
};

export default Where;
