import React, { useEffect } from "react";
import img from "../../Images/freezone.png";

import SmallStrip from "../../components/SmallStrip";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const FreeZone = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>FreeZone</title>
        <meta name="description" content="All license here" />
      </Helmet>
      <SmallStrip data="FreeZone" data2="Licensing" />

      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded margin-top4" />
          </div>
          <div className="col-md-6 col-lg-7 col-xl-8">

            <p className="h3 bold-text ">Dubai Freezone License, UAE</p>

            <p className="font-size-para">
              Dubai is considered to be one of the first emirates to pioneer the free zone model of business, thereby offering tremendous concessions and other investment incentives to foreign entrepreneurs inclusive of subsidiary complete ownership and no taxation. A total of about 24 free zones are currently active, which have been developed with specific industries or sectors in mind like ICT, finance, gold, media, and health care. While there are exemptions of corporate tax and customs duty on imported materials and equipment, and no levy on imports or exports, the need for a Free zone license is mandatory for any type of business in the free zone.  A free zone license from the respective free zone authority permits national or international companies to start a new branch office or shift completely into the free zone. If one is interested to start a company in the free zone area in Dubai, good research into the various types of free zone areas and their benefits is necessary to be able to find the most favorable fit that best enhances the profits one can get when working in the respective free zone area. Many headline benefits of operating a business in the UAE like zero taxation on both corporate as well as personal income are good but so is working in Dubai a strategic geographically business-supporting location. Diplomat Business Solutions (DBS) has a way of offering the best option to receive business support services, also repatriate all capital and profits with no currency restrictions when operating within the Free zone.
            </p>

            <Link to="/contact" className="button-animation">
              Contact us
            </Link>
            <br></br>
          </div>
        </div>
      </div>
      <br></br>


      <br></br>
      <div className="container-fluid padding3">
        <div className="row">
          <center>
            <p className="h1 bold-text ">FreeZone</p>
          </center>
          <br></br>

          <br></br>
          <br></br>
          <p>
            When it comes to Mainland Companies, the foreign investor can now
            obtain the license under 100% ownership. The greatest benefit is
            that the mainland company can operate the business across the length
            and breadth of the UAE. Mainland company operations are not
            restricted to the geographical boundaries of the free zone. Here are
            the most preferred locations to start Mainland company in Dubai.
          </p>
          {/* 
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div> */}
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>


      <div className="back-img-how-back text-dark">
        <br></br>

        <p className="h4">Some Fundamental Formalities when setting up a business in Dubai!</p>
        <br></br>
        <br></br>
        <div className="row">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-6 col-lg-4   mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className=" bold-text d-flex" style={{ textTransform: "capitalize" }} >What Is The Relevance of a Dubai Mainland Company? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2" >The mainland company often referred to as an onshore company is a company registered at the Department of Economic Development in Dubai. Thereby availing a trouble-free trading experience in Dubai free of unnecessary taxing. Also, the location for the office space is easy as there are no geographical boundaries for the same. Recent Companies Law amendments allow foreigners a chance to own the complete mainland business. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex" style={{ textTransform: "capitalize" }} >What Are The Essential Steps When Applting For a Dubai Mainland Company License?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">For a Dubai mainland company license, many government organization approvals are required. The essential steps that seek approval include the selection of business activity and trade name, an initial certificate for mainland company setup, notarization by the court, annotation of the associative team, choice of office space location and tenancy requirements, final submission letter, and assimilation of documents.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">IN A MAINLAND BUSINESS, HOW MANY VISAS CAN BE OBTAINED?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Diplomat Documents Services (DDS) ensures you are aware of exactly how many visas you can apply for when opening a business in mainland Dubai. While there are no direct restrictions against the number of visas but every 9 sqm of office space allows the allotment of about one visa. Therefore, the bigger the office space more the visas your company may receive. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">UAE Branch Office Set-Up</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Setting up a business in UAE can be done in many ways, but methods to retain complete ownership while doing so are trickier. Hence opening the branch office to an already existing company is often a popular choice among immigrant entrepreneurs. DDS is able to help guide you through all the legal paperwork and hassles if this is how you plan to set up an office.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">Corporate Banking</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Corporate banking is one of the most integral formalities that need to be dealt with when one opts to establish one’s startup in Dubai. It can also be complicated when trying to open a bank account on your own. Hence, DDS offers all the banking assistance one may require from starting a banking account for corporate purposes to even managing the same once the company has been set up.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <br></br>
        <br></br>
      </div>
      <br></br>

      <hr></hr>
    </>
  );
};

export default FreeZone;
