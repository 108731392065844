import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 27px;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
  transition: 0.7s linear;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 10px;
  font-weight: 500;
  transition: 0.7s linear;
`;


const SubMenu = ({ item, isOpen }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink
        to={item.path}
        onClick={item.subNav && showSubnav}
        className="overflow-hidden"
      >
        <div className="d-flex">
          <p
            style={{
              display: "inline",

              color: subnav ? "white" : "grey",
            }}
          >
            {" "}
            {item.icon}{" "}
          </p>
          <SidebarLabel
            style={{
              display: isOpen ? "inline" : "none",
              color: subnav ? "white" : "grey",
              marginTop: "-5px"
            }}
          >
            <Link className="mt-1" style={{ textDecoration: "none" }} to={item.path} >{item.title} </Link>
          </SidebarLabel>
        </div>

      </SidebarLink>


    </>
  );
};

export default SubMenu;