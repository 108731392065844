import React, { useEffect, useRef } from "react";
import "./navbar.css";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose, AiOutlineFacebook, AiOutlineInstagram, AiOutlineSkype, AiOutlineWhatsApp, AiOutlineYoutube } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import { RiArrowDropRightFill } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";
import logo from "../Images/logo.png"


const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);


  // useEffect(() => {
  //   AOS.init();
  // }, []);
  const menuRef = useRef()

  useEffect(() => {
    const handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setShowMediaIcons(false)
      }
    }
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }


  })
  return (
    <>
      <div className="container-fluid diplay-non-500" style={{ background: "linear-gradient(to right, #0066cc 0%, #00ccff 100%)" }}>
        <div className="container text-white " style={{ justifyContent: "space-between", display: 'flex', padding: '0px', marginBottom: "-0.5rem" }}>
          <div className="d-flex  mt-1">
            <div className="d-flex mx-2">
              <AiOutlineWhatsApp className="nva-icon mt-1 size-text" style={{ marginTop: "1px" }} />
              <p className="mx-1 nva-icon size-text ">+971 50 887 8699</p>
            </div>
            <div className="d-flex mx-2 ">

              <FiPhoneCall className="nva-icon mt-1 size-text" style={{ marginTop: "2px" }} />
              <p className="mx-1 nva-icon size-text ">+971 52 895 1898</p>
            </div>
          </div>
          <div className="d-flex mt-1">
            <AiOutlineInstagram className="h4  nva-icon mx-1" />
            <AiOutlineFacebook className="h4 nva-icon mx-1" />
            <AiOutlineYoutube className="h4 nva-icon mx-1" />
            <AiOutlineSkype className="h4  nva-icon mx-1" />
          </div>
        </div>
      </div>

      <section className="header-custom" style={{ position: "sticky" }} >
        <div className="d-flex">

          <Link to="/" className="margin-left-500 " style={{ marginLeft: "0rem", textDecoration: "none" }}>
            <img src={logo} className="mt-1 logo-size" style={{ height: "130px", marginLeft: "-1rem" }} alt="error" />
          </Link>
          <div style={{ marginTop: "1.7rem", borderLeft: "3px solid lightgrey", height: "4.9rem" }} className="margin-left-1"  >
            <p className="mx-1 mt-1 bold-text text-primary">Diplomat <br></br>Documents<br></br>Service</p>
          </div>
        </div>


        <nav className={showMediaIcons ? "navbar active" : "navbar"} ref={menuRef}>
          <ul className="menu">
            <li>
              <div className="dropdown">
                <Link className="dropbtn d-flex drop-link" >
                  Business-Guide <MdArrowDropDown className="mt-1" />
                </Link>
                <div className="dropdown-content">
                  <li style={{ marginTop: "-0.4rem" }}>
                    <Link to="/where" style={{ marginBottom: "-0.5rem", marginLeft: "-0.4rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>Where</Link>
                    <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "3rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                  </li>
                  <li style={{ marginTop: "-0.6rem" }}>
                    <Link to="/how" style={{ marginBottom: "-0.5rem", marginLeft: "-0.4rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>How</Link>
                    <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "2.5rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                  </li>
                  <li style={{ marginTop: "-0.4rem" }}>
                    <Link to="/why" style={{ marginTop: "-0.5rem", marginLeft: "-0.4rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>Why</Link>
                    <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "2.5rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                  </li>
                </div>
              </div>
            </li>

            <li>
              <div className="dropdown">
                <Link className="dropbtn d-flex drop-link">
                  Licensing <MdArrowDropDown className="mt-1" />
                </Link>
                <div className="dropdown-content">
                  <li className="dropdown2">
                    <Link to="/mainland" className="d-flex" style={{ marginLeft: "-0.5rem" }}>
                      Mainland License{" "}
                      <RiArrowDropRightFill fontSize="20px" className=" " />
                    </Link>
                    <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                    <ul className="dropdown-content2">
                      <li>
                        <Link to="/professional" style={{ marginLeft: "-0.5rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>Professional License</Link>{" "}
                        <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                      </li>
                      <li>
                        <Link to="/commericial" style={{ marginLeft: "-0.5rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>Commercial License</Link>{" "}
                        <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                      </li>
                      <li>
                        <Link to="/industrial" style={{ marginLeft: "-0.5rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>Industrial License</Link>{" "}
                        <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                      </li>
                      <li>
                        <Link to="/tourism" style={{ marginLeft: "-0.5rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>Tourism License</Link>{" "}
                        <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                      </li>
                      <li>
                        <Link to="/etrade" style={{ marginLeft: "-0.5rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>E Trader License</Link>{" "}
                        <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                      </li>
                    </ul>
                  </li>
                  <li >
                    <Link to="/freezone" style={{ marginLeft: "-0.5rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)} >Free zone License</Link>{" "}
                    <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                  </li>
                  <li>
                    <Link to="/offshore" style={{ marginLeft: "-0.5rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)} >Offshore License</Link>{" "}
                    <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                  </li>
                </div>
              </div>
            </li>
            <li>
              <div className="dropdown">
                <Link className="dropbtn d-flex drop-link" >
                  Visa <MdArrowDropDown className="mt-1" />
                </Link>
                <div className="dropdown-content" >
                  <li>
                    <Link to="/businessvisa" style={{ marginLeft: "-0.5rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>Dubai-Business visa</Link>{" "}
                    <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                  </li>
                  <li>
                    <Link to="/investorvisa" style={{ marginLeft: "-0.5rem" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>Dubai-Investor visa</Link>{" "}
                    <p style={{ borderBottom: "2px solid rgb(52 114 125)", width: "7rem", marginLeft: "1rem", marginTop: "-0.6rem" }}></p>
                  </li>
                </div>
              </div>
            </li>
            <li>
              <Link to="/about" className="drop-link" style={{ fontWeight: "bold" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>
                About US{" "}
              </Link>{" "}
            </li>
            <li>
              <Link to="/contact" className="drop-link" style={{ fontWeight: "bold" }} onClick={() => setShowMediaIcons(!showMediaIcons)}>
                Contact Us
              </Link>{" "}
            </li>
            {/* 
            <li>
              <Link to="/adminpanel/aboutus" className="drop-link" onClick={() => setShowMediaIcons(false)}>
                Adminpanel
              </Link>
            </li> */}
          </ul>

          <AiOutlineClose
            className="display  mx-3"
            onClick={() => setShowMediaIcons(!showMediaIcons)}
          />
        </nav>

        <div className="hamburger-menu ">
          <Link onClick={() => setShowMediaIcons(!showMediaIcons)}>
            <GiHamburgerMenu className="icons " />
          </Link>
        </div>
      </section>
    </>
  );
};

export default Navbar;
