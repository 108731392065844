import React, { useEffect } from "react";
import img from "../../../Images/2.png";

import SmallStrip from "../../../components/SmallStrip";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Etrade = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Etrade License</title>
        <meta name="description" content="All license here" />
      </Helmet>

      <SmallStrip data="Etrade" data2="Licensing" />

      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-3 col-lg-5 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded margin-top4" />
          </div>
          <div className="col-md-9 col-lg-7 col-xl-8">

            <p className="h3 bold-text ">E Trader License in Dubai</p>

            <p className="font-size-para">
              Considering small business ideas in Dubai you would like to see into fruition that would not require a working space outside of your home? But the fear of the complications of the processes involved may be a turn-off. Not anymore, Diplomat Documents Service  (DDS) suggests an E- Trader license launched in 2017 by the Department of Economic Development (DED). This easily available E-Trader license can help stay-at-home entrepreneurs to sell not just products but even services on social media, for example, once you have obtained the license you can sell handmade pieces of jewelry or other accessories through social media accounts like Instagram from the comforts of your home. While the E-trader license essentially helps regulate home entrepreneurs doing their business over social media platforms in Dubai, it also acts in bolstering the customers’ confidence when shopping online. The reason DED allowed the issuance of an E-Trade license is as a method to stop counterfeit or fake products from being sold through social media platforms. So, if selling products or services online even through a personal website in Dubai is what you have in mind, an E-Trader license is what you will need to flourish as a business.  One does not need to look for a physical office space or even register with Ejari Dubai to be able to promote handmade goodies or even services like photography without owning a studio. This is a boon for stay-at-home mothers. One does not need a local partner or sponsor and can have 100% ownership hence it is quite ideal for single-owner home-based businesses or freelancers.
            </p>

            <Link to="/contact" className="button-animation">
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <br></br>
      <br></br>
      <br></br>
      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">FREQUENTLY ASKED QUESTIONS</p>
        <br></br>
        <div className="row">

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow " style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className=" bold-text d-flex">WHAT IS THE IMPORTANCE OF AN E-TRADER LICENSE IN THE UAE?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Exponential e-commerce growth as a result of the internet and social media allows easy business for both investors and consumers. As a mode of regulation of this industry to prevent fraud while buying products online in Dubai, the DED launched the E-Trader license. FBS has pertinent information regarding all the conditions for applying for this license and how to go about doing so. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex"  >WHAT ARE THE PERKS OF AN E-TRADER LICENSE IN DUBAI? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2">With An E-Trader license, one can legally promote as well as sell products or services through social media accounts like Instagram or Facebook or even a personal website. Also allows featuring one’s products and services on online marketplaces or legally participating in exhibitions and conferences. This license also protects your trading name or any other intellectual domains while permitting flourishing as a home-based business or as a freelancer.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">CAN YOU SELL PRODUCTS OUTSIDE OF DUBAI IF YOU HAVE AN E-TRADER LICENSE? </p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Once you have obtained an E-trader license in Dubai, you are allowed to sell products and services only within the emirate. There is a need for approval from the respective DED of other emirates before you can trade even online to other emirates. Often one may have to obtain a separate respective E-Trader license for another emirate to sell there.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">HOW OFTEN DO I NEED TO RENEW MY E-TRADER LICENSE?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">As the DED issues the E-Trader license to home entrepreneurs who either sell their products or services on social media platforms or personal websites online, it is given for just a year. And so should be renewed every year. FBS can get you set up with the license so there are no delays, after which renewal can be done online on the website of DED. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">HOW CAN FBS LLC HELP WITH APPLYING FOR AN E-TRADER LICENSE IN DUBAI?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">For those who want to legally start your e-commerce business in Dubai, FBS is here to help. We will ensure a breezy application process with very little time and effort from your side and no delays in being issued an E-Trader license from the DED. Our consultation also includes advice on how best to operate the business to profit from it the most. </p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>



      <hr></hr>
    </>
  );
};

export default Etrade;
