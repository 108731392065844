import React, { useEffect } from "react";
import img from "../../../Images/15.png";

import SmallStrip from "../../../components/SmallStrip";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Professional = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Professional License</title>
        <meta name="description" content="All license here" />
      </Helmet>
      <SmallStrip data="Professional" data2="Licensing" />


      <div className="container-fluid padding3 marginTop-500">
        <div className="row">
          <div className="col-md-3 col-lg-5 col-xl-4 center">
            <img src={img} alt="error" className="img-fluid rounded margin-top4" />
          </div>
          <div className="col-md-9 col-lg-7 col-xl-8">
            <p className="h3 bold-text ">
              Professional License in Dubai, UAE
            </p>

            <p className="font-size-para">
              Anyone who has been to Dubai can attest to the fact that it is home to elites in the business world as the lifestyle showcased here is luxurious and second to none around the globe. To maintain professionality in the trading world, Dubai has many policies and procedures that aim to help pioneers find success in their journey to grow professionally. The best part of investing in UAE is how the economic and fiscal protocols are especially foreigner-friendly. Yet, Dubai shines in how it manages a company’s security and privacy concerns through the strength of a well-founded legal structure that ensures proper documentation of businesses small or large. Choosing Dubai among the emirates in the UAE for setting up a company requires that the person applies for a variety of licenses and permissions. All service-oriented companies are required by the government to procure a Professional trade license before they begin. Acquirement of the trade license has a lot of law-based hurdles that need to be crossed, wherein Diplomat Business Services (DDS) step in to make the process both effortless and cost-effective. Dubai professional trade licenses are only given to those whose educational entitlement and area of expertise are deserving. What makes a professional license different from industrial and commercial licenses, is that it allows complete ownership to foreign investors hence permitting them all the advantages of a sole proprietorship. Though it may be mandatory to appoint a local Emirati as a service agent to complete all judicial formalities, the person will not have any direct involvement, no equity, and are not incumbent upon to pay for any liabilities of the company, and hence need to be paid a fixed annual fee for the service offered.
            </p>
            <Link to="/contact" className="button-animation">
              Contact us
            </Link>



          </div>
        </div>
      </div>
      <br></br>
      <br></br>


      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">FREQUENTLY ASKED QUESTIONS</p>
        <br></br>
        <div className="row">
          <div className="col-md-4  mt-2">
            <div className="card-faq shadow" >
              <div className="mx-4">
                <p className=" d-flex bold-text " style={{ height: "100%" }}>WHAT IS THE RELEVANCE OF A PROFESSIONAL LICENSE?</p>
                <p>In simple terms, the professional license is meant for either professionals or companies that deal with either professional or even service-oriented business activities. The obtainment of a professional license is an integral part of the process of establishing a professional firm like a professional tuition center or even an art center and DDS will help you do just that in a cost and time-effective manner. </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="card-faq shadow" style={{ height: "100%" }}>
              <div className="mx-4">
                <p className="bold-text  d-flex">HOW TO GET INITIAL APPROVAL FOR A PROFESSIONAL LICENSE? </p>
                <p>To apply for a professional license in Dubai, one must first obtain initial approval. Once a precise description of the business idea planned is made DDS can help by performing the official formalities in a systematic manner in order to get the initial approval from the DED. Next one must find and reserve a compatible trade name in accordance with government stipulations. </p>
              </div>
            </div>
          </div>
          <div className="col-md-4  mt-2">
            <div className="card-faq shadow" style={{ height: "100%" }}>
              <div className="mx-4">
                <p className="bold-text  d-flex"> WHAT ARE THE DOCUMENTS ONE REQUIRES TO OBTAIN A PROFESSIONAL LICENSE?</p>
                <p>Documents include a filled application form with the passport copy of the owner of the business, and a naturalization book copy of the partner, expatriate partners may require a sponsor’s NOC if not on a tourist or visit visa, in which case the UID number may be sufficient. DDS then submits these documents along with professional qualification and experience documents if applicable to the DED. </p>
              </div>
            </div>
          </div>
          <div className="col-md-4  mt-2">
            <div className="card-faq shadow" style={{ height: "100%" }}>
              <div className="mx-4">
                <p className="bold-text  d-flex">IS THE APPLICATION PROCESS FOR A PROFESSIONAL LICENSE A LONG ONE?</p>
                <p>DDS helps shorten the process considerably by being an experienced entity in the field of the company or service-related business setup. Hence are capable of navigating the laws which may be foreign to you in the right manner so as to help obtain the professional license as soon as possible with minimum effort often in a matter of a few weeks. </p>
              </div>
            </div>
          </div>
          <div className="col-md-4  mt-2">
            <div className="card-faq shadow" style={{ height: "100%" }}>
              <div className="mx-4">
                <p className="bold-text  d-flex">HOW CAN DDS LLC HELP WITH THE OBTAINMENT OF A PROFESSIONAL LICENSE?</p>
                <p>Foreign entrepreneurs who want to start a service-related business in the mainland of Dubai or anywhere else in the UAE must have a clear understanding of the laws involved in each part of the country as mainland laws are different from the ones in the free zone etc. Here DDS takes over the heavy-duty needed to reach your goal. </p>
              </div>
            </div>
          </div>

        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </div>
      <br></br>

    </>
  );
};

export default Professional;
