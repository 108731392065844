import React, { useEffect } from "react";
import img from "../../Images/b1.png";

import SmallStrip from "../../components/SmallStrip";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// import { AccordionIcon } from "@chakra-ui/icons";
const How = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>How to Start Business in Dubai?</title>
        <meta name="description" content="All license here" />
      </Helmet>
      <div className="">
        <SmallStrip data="How" data2="Business-Guide" />

        <div className="container-fluid padding3 marginTop-500">
          <div className="row">
            <div className="col-md-6 col-lg-4 col-xl-4">
              <img src={img} alt="error" className="img-fluid rounded margin-top4 " />
            </div>
            <div className="col-md-6 col-lg-8 col-xl-8">
              <p className="h3 bold-text test-white ">
                How to Start Business in Dubai?
              </p>
              <p className="font-size-para">
                Venturing into trading, especially in a foreign country can be daunting, but with DBS on your side, all things are easier and the processing smoother. Our need to help pioneering entrepreneurs like yourself to not just start right but to start with a firm groundwork so that you can finally stand on your own is often the reason we have so many clients.  As consultants in the business setup arena, we maintain an easy-to-understand process that helps you to not just choose where in Dubai do you initiate your company like whether it is to be in the mainland or free zone but also the right activities you need to be involved in based on what you have in mind. DBS plans to be with you throughout all the required legal formalities inclusive of paperwork, getting business license and opening your corporate bank account.  Our initial consultation is as friendly as its professional and encourages you to ask us anything related to the entire process of firm setup and we also can help ensure that you are ready both mentally and fiscally before the processing begins. We also help in translation and legalization services, corporate PRO services as well as product registration. On agreeing to consult with us, you benefit from a personal confrontation that gives us a better understanding of your needs and so will exceed expectations regarding corporate resources and services from our side that can help you create your own niche of trade anywhere in the UAE.
              </p>

              <Link to="/contact" className="button-animation">
                Contact us
              </Link>
            </div>

          </div>
        </div>
        <br></br>

      </div>
      <div className="back-img-how-back text-dark">
        <br></br>
        <br></br>
        <p className="h4">Some Fundamental Formalities when setting up a business in Dubai!</p>
        <br></br>
        <div className="row">
          {/* <div className="col-md-4 mt-2">
            <p className="h4 d-flex">A FAQ  is a list </p>
            <p className="">A FAQ  is a list of common questions and answers that provide information about a particular subject, product, service, or company. The purpose of a FAQ is to provide quick and easily accessible information to users, reducing the need for them to contact support or perform extensive research A FAQ  is a list of common questions and answers that provide information about a particular subject, product, service, or company. The purpose of a FAQ is to provide quick and easily accessible information to users, reducing the need for them to contact support or perform extensive research.</p>
          </div> */}
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4  mt-2" >
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className=" bold-text d-flex"> Choosing the Right Company/Trade/Business Name ?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">In the UAE, a company name is quite important, and has a registration process for the same. The company is expected to adhere to specific naming stipulations that are fairly straightforward and clear. Another key factor is how restrictive the naming process gets if your enterprise is based on the mainland as opposed to the more liberal naming method in the free zone or offshore businesses.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex" >Need for Local Partner for an Expat as a Business Requirement ?</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Deciding whether your business idea needs a national partnership in UAE is relevant. General trading and commercially licensed companies need a local sponsor, who owns 51% of company shares. This does not mean all business setups need one in UAE, for example, professional companies can function under 100% foreign ownership. Also, small startups or coffee shops, etc. can also enjoy complete ownership. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">Hiring Process for your Company </p>
                    <p style={{ fontSize: "13px" }} className="mt-2">It is quite a challenging process that requires not just Labour law comprehension but working toward the process in a knowledgeable and sensible manner. When hiring, it’s essential to put together a standard work contract and be aware of the various stipulations based on the sort of employment contracts on offer.  Guidelines are different when hiring if your business operates on the mainland or is based within the free zone.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">UAE Branch Office Set-Up</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Setting up a business in UAE can be done in many ways, but methods to retain complete ownership while doing so are trickier. Hence opening the branch office to an already existing company is often a popular choice among immigrant entrepreneurs. DBS is able to help guide you through all the legal paperwork and hassles if this is how you plan to set up an office.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  mt-2">
                <div className="card-faq shadow" style={{ height: "100%" }}>
                  <div className="mx-4">
                    <p className="bold-text d-flex">Corporate Banking</p>
                    <p style={{ fontSize: "13px" }} className="mt-2">Corporate banking is one of the most integral formalities that need to be dealt with when one opts to establish one’s startup in Dubai. It can also be complicated when trying to open a bank account on your own. Hence, DBS offers all the banking assistance one may require from starting a banking account for corporate purposes to even managing the same once the company has been set up.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </div>





    </>
  );
};

export default How;
